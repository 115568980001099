import React from 'react';
import {ModalDisplayBinder} from "../KnarkbokModal";
import "./Move.css"
import {KnarkbokService} from "../Knarkbokservice";
import {Binder, BinderId, Book, BookId, Drug, KnarkbokType} from "../models/Models";
import {Organisation} from "../../model/Organisation";
import {EventBackendService} from "../../register/v2/EventBackendService";
import {BackendContext, BackendInterface} from "../../../infra/BackendContext";
import TranslationService from "../../../infra/TranslationService";
import UserSessionUtilService from "../../model/UserSessionUtilService";
import {Resolution} from "../../../infra/Constants";

const ADDITIONALINFOFLAG = "other-fitymi-additional-info";

interface props {
    book: ModalDisplayBinder;
    KBService: KnarkbokService;
    organisation: Organisation;
    closeModal: (bookId?: BookId, sentEmailWarning?: boolean) => void
    prefill?: any //todo obj....
}

interface state {
    spinner: boolean;
    operation: KnarkbokType | undefined;
    binders: Binder[];
    amount: string | undefined;
    displayWarning: boolean;
    showComment: boolean;
    comment: string | undefined;
    oldBalance: number;
    newBalance: string | undefined;
    remoteBook: BookId | undefined;
    remoteLocationDisplayString: string;
    showAdditionalDetails: boolean
    additionalDetails: string;
}

class Move extends React.Component<props, state> {
    static contextType: React.Context<BackendInterface> = BackendContext;

    constructor(props: any) {
        super(props);

        const oldBalance: number = this.props.book.inventory;
        let tempState: state = {
            operation: undefined,
            spinner: true,
            binders: [],
            amount: '',
            displayWarning: false,
            showComment: false,
            comment: undefined,
            oldBalance: oldBalance,
            newBalance: '',
            remoteBook: undefined,
            remoteLocationDisplayString: '',
            showAdditionalDetails: false,
            additionalDetails: ""
        };
        const {prefill} = this.props
        if (prefill) {
            tempState = {
                ...tempState,
                operation: prefill.operation,
                showAdditionalDetails: prefill.showAdditionalDetails
            }
        }

        this.state = tempState
    }

    componentDidMount() {
        this.getBindersFromBackend();
    }

    render(): React.JSX.Element {
        let operation = this.state.operation;
        if (operation === undefined) {
            return this.selectOperationDialogue();
        }

        if (this.state.spinner) {
            return this.getSpinner()
        }

        let direction: KnarkbokType;
        let from: React.JSX.Element;
        let to: React.JSX.Element;
        const binders: Binder[] = this.getBinders();

        if (operation === KnarkbokType.move_neg) {
            direction = KnarkbokType.move_neg;
            const thisDrugBook: React.JSX.Element = this.getThisDrugBook();
            from = this.getFrom(thisDrugBook);
            const selectHeader = '-- Destination --';

            const otherDrugBooks: React.JSX.Element = this.getOtherDrugBooks(binders, selectHeader);
            to = this.getTo(otherDrugBooks);
        } else {
            direction = KnarkbokType.move_pos;
            const selectHeader = '-- Ursprung --';
            const otherDrugBooks = this.getOtherDrugBooks(binders, selectHeader);
            from = this.getFrom(otherDrugBooks);

            const thisDrugBook: React.JSX.Element = this.getThisDrugBook();
            to = this.getTo(thisDrugBook);
        }

        return this.moveDialogue(direction, from, to);
    }

    private selectOperationDialogue(): React.JSX.Element {
        const buttonClass: string = 'btn btn-tage-drug-book-move';

        return <div className={'row'}>
            <div className={"col text-center"}>
                <button aria-label={'move out'}
                        className={buttonClass}
                        onClick={() => this.selectOperation(KnarkbokType.move_neg)}>
                    Plocka ut
                </button>
            </div>
            <div className={"col text-center"}>
                <button aria-label={'move in'}
                        className={buttonClass}
                        onClick={() => this.selectOperation(KnarkbokType.move_pos)}>
                    Föra in
                </button>
            </div>
        </div>
    }

    private getSpinner() {
        return <div aria-label={"move-drug-spinner"}
                    className={"spinner"}/>;
    }

    private moveDialogue(direction: KnarkbokType, from: React.JSX.Element, to: React.JSX.Element): React.JSX.Element {
        const {tr} = this;
        const {showAdditionalDetails} = this.state;
        const moveInput: React.JSX.Element = this.getMoveInput();
        const localCheck: React.JSX.Element = this.getLocalCheck();
        const commentField: React.JSX.Element = this.getComment();
        const warning: React.JSX.Element = this.getWarning(direction);
        const buttons: React.JSX.Element = this.getButtons(direction);

        let fromAdditional = <div/>;
        let toAdditional = <div/>;

        let descriptionText;
        let sideFrom;
        if (this.props.prefill && this.props.prefill.closeOtherOptions) {
            descriptionText = "";
            sideFrom = tr("Från");
        } else {
            descriptionText = tr("to from whom name");
            sideFrom = "";
        }

        const additionalDetails = <div className={"container m-0 p-0"}>
            <div className={"row m-0 pb-1 pl-0 pr-0"}>
                <div className={"col-3"}></div>
                <div className={"col m-0 p-0"}>
                    {descriptionText}
                </div>
                <div className={"col-3"}></div>
            </div>
            <div className={"row m-0 p-0"}>
                <div className={"col-3 text-right"}>
                    {sideFrom}
                </div>
                <div className={"col-6 m-0 p-0"}>
                    <input type={"text"} style={{width: "100%"}} value={this.state.additionalDetails}
                           onChange={(e) => this.handleAdditionalDetails(e)}/>
                </div>
                <div className={"col-3"}></div>
            </div>
        </div>

        if (direction === KnarkbokType.move_neg && showAdditionalDetails) {
            toAdditional = additionalDetails;
        }
        if (direction === KnarkbokType.move_pos && showAdditionalDetails) {
            fromAdditional = additionalDetails
        }


        return (
            <div className={"container m-0 p-0"}>
                {from}
                {fromAdditional}
                {to}
                {toAdditional}
                {moveInput}
                {localCheck}
                {commentField}
                {warning}
                {buttons}
            </div>
        );
    }

    private getBinders(): Binder[] {
        const binders: Binder[] = this.state.binders;
        const currentBinder: BinderId = this.props.book.binderId;

        return binders.filter((binder: Binder) => binder.id.id !== currentBinder.id);
    }

    private getFrom(drugBook: React.JSX.Element) {
        if (this.props.prefill && this.props.prefill.closeOtherOptions) {
            return <></>
        }
        return <div className={"row m-0 pb-1"}>
            <div aria-label={'from journal'}
                 className={"col-3 text-right"}>
                Från
            </div>
            {drugBook}
        </div>;
    }

    private getOtherDrugBooks(candidates: Binder[], selectHeader: string) {
        const {tr} = this;
        const defaultValue: string = "default_empty";
        const topValue: React.JSX.Element = <option disabled value={defaultValue}>
            {selectHeader}
        </option>;
        const options: React.JSX.Element[] = candidates.map((binder) =>
            <option key={binder.id.id}
                    value={binder.id.id}>{binder.locationName}</option>
        );

        const other = <option key={"other-option"} value={ADDITIONALINFOFLAG}>
            {tr("other-dest-source")}
        </option>

        return <>
            <div
                className={"col-6 m-0 p-0"}>
                <select className={"max-size-input"}
                        defaultValue={defaultValue}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.handleDrugBookSelection(e)}
                >
                    {topValue}
                    {options}
                    {other}
                </select>
            </div>
            <div className={"col-3"}>
            </div>
        </>;
    }

    private getTo(drugBook: React.JSX.Element) {
        return <div className={"row m-0 pb-1 pt-1"}>
            <div aria-label={'to journal'}
                 className={"col-3 text-right"}>
                Till
            </div>
            {drugBook}
        </div>;
    }

    private getThisDrugBook() {
        const placeHolder: string = this.props.book.location;

        return <>
            <div className={"col-6 m-0 p-0"}>
                <input className={"max-size-input"}
                       size={10}
                       disabled={true}
                       placeholder={placeHolder}/>
            </div>
            <div className={"col-3"}>
            </div>
        </>;
    }

    private getMoveInput() {
        let amount: string | undefined = this.state.amount;
        const type = UserSessionUtilService.getResolution() === Resolution.Desktop ? "text" : "number"

        return <div className={"col m-0 p-0 pb-1 pt-2 justify-content-center"}>
            <div className={"row m-0 p-0"}>
                <div className={"col"}></div>
                <div className={"col-6 m-0 p-0"}>
                    <div className={"row m-0 pb-1"}>
                        Antal:
                    </div>
                    <div className={"row m-0 p-0"}>
                        <input aria-label={'amount'}
                               className={"max-size-input mr-0"}
                               onChange={(e) => this.handleMove(e)}
                               value={amount} type={type}/>
                    </div>
                </div>
                <div className={"col"}></div>
            </div>
        </div>;
    }

    private getLocalCheck(): React.JSX.Element {
        const newBalance: string | undefined = this.state.newBalance;
        const type = UserSessionUtilService.getResolution() === Resolution.Desktop ? "text" : "number"

        return <div className={"col m-0 p-0 pb-1"}>
            <div className={"row m-0 p-0"}>
                <div className={"col"}></div>
                <div
                    className={"col-6 m-0 p-0"}>
                    <div className={"row m-0 p-0"}>
                        Nytt saldo:
                    </div>
                    <div className={"row m-0 pb-1"}>
                        <input aria-label={'new balance'}
                               value={newBalance}
                               className={"max-size-input mr-0"}
                               onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleLocalCheck(e)}
                               type={type}/>
                    </div>
                </div>
                <div className={"col"}></div>
            </div>
        </div>;
    }

    private getComment() {
        let showHideComment: string;
        if (this.state.showComment) {
            showHideComment = "-";
        } else {
            showHideComment = "+";
        }
        const commentFlip: React.JSX.Element = <div
            aria-label={'show hide comment'}
            className={"ml-2"}
            title="Lägg till kommentar">
            <button className={"btn btn-tiny-expand"}
                    onClick={(e) => this.flipComment(e)}
            >
                {showHideComment}
            </button>
        </div>;

        let commentField: React.JSX.Element;
        if (this.state.showComment) {
            commentField = <div>
                <div className={"row m-0 pb-1"}>
                    <div className={"col"}></div>
                    <div className={"col-6 m-0 p-0"}>
                        <div className={"row m-0 pt-2"}>
                            Kommentar:
                            {commentFlip}
                        </div>
                    </div>
                    <div className={"col"}></div>
                </div>
                {
                    <React.Fragment>
                        <div className={"row ml-0 pl-0 pr-3"}>
                            <div className={"col"}></div>
                            <div className={"col-6 m-0 p-0"}>
                        <textarea value={this.state.comment}
                                  className={"max-size-input"}
                                  onChange={(e) => this.handleChangeComment(e)}
                        />
                            </div>
                            <div className={"col"}/>
                        </div>
                    </React.Fragment>
                }
            </div>;
        } else {
            commentField = <div>
                <div className={"row m-0 pb-1"}>
                    <div className={"col"}></div>
                    <div className={"col-6 m-0 p-0"}>
                        <div className={"row m-0 pt-2"}>
                            Kommentar:
                            {commentFlip}
                        </div>
                    </div>
                    <div className={"col"}></div>
                </div>
            </div>;
        }
        return commentField;
    }

    private getWarning(direction: KnarkbokType) {
        const displayWarning: boolean = this.state.displayWarning;
        if (displayWarning) {
            return <div>
                <div className={"row m-0 p-0 pb-3 justify-content-center"}>
                    <h5 className={"innermodal-warning-text"}>
                        Är du säker på att värdena är korrekta?
                    </h5>
                </div>
                <div className={"row m-0 pr-4 justify-content-end"}>
                    <button className={"btn btn-red"}
                            onClick={() => this.move(direction, true)}>
                        Jag är säker
                    </button>
                    <button className={"btn btn-tage-default-cancel  ml-2 mr-2"}
                            onClick={() => this.closeWarning()}>
                        Avbryt
                    </button>
                </div>
            </div>;
        } else {
            return <div/>;
        }
    }

    private getButtons(direction: KnarkbokType) {
        const displayWarning: boolean = this.state.displayWarning;
        const remoteLocation: string = this.state.remoteLocationDisplayString;

        if (displayWarning) {
            return <div/>;
        } else {
            const localLocation: string = this.props.book.location;
            let disabled: boolean = !remoteLocation ||
                !this.state.amount ||
                !localLocation ||
                !this.state.newBalance;

            if (this.state.showAdditionalDetails && this.state.amount && this.state.newBalance && (localLocation || remoteLocation)) {
                disabled = false;
            }

            return <div className={"row m-0 pr-4 justify-content-end"}>
                <button aria-label={'ok'}
                        disabled={disabled}
                        className={"btn btn-tage-default"}
                        onClick={() => this.move(direction, false)}>
                    OK
                </button>
                <button aria-label={'cancel'}
                        className={"btn btn-tage-default-cancel ml-2 mr-2"}
                        onClick={() => this.closeModal()}>
                    Avbryt
                </button>
            </div>;
        }
    }

    private selectOperation(operation: KnarkbokType): void {
        this.setState({
            operation: operation
        })
    }

    private async getBindersFromBackend() {
        let organisation: string = this.props.organisation.organisationId;
        const path: string = `/api/v1/knarkbok/binders/${organisation}`
        const url: string = EventBackendService.getUrl2(path);
        const response: any = await this.context.get(url, {
            success: "",
            failure: ""
        }) as any;

        let binders: Binder[] = [];
        if (response.data !== undefined) {
            binders = response.data;
        }

        this.setState({
            spinner: false,
            binders: binders
        });
    }

    private async move(direction: KnarkbokType, forceSubmit: boolean) {
        const {showAdditionalDetails} = this.state;
        const amount: string | undefined = this.state.amount;
        const newLocalInventory: string | undefined = this.state.newBalance;
        const remoteBook: BookId | undefined = this.state.remoteBook;

        if (amount !== undefined &&
            newLocalInventory !== undefined &&
            (remoteBook !== undefined || showAdditionalDetails)) {

            const oldBalance: number = this.state.oldBalance;
            const newBalance: string = newLocalInventory;
            const cleanedAmount: number = Number(amount);

            const service: KnarkbokService = this.props.KBService;
            const localCheck: boolean = service.aboutToSubmitCheck(oldBalance, '' + newBalance, direction, cleanedAmount);

            if (localCheck || forceSubmit) {
                const book: ModalDisplayBinder = this.props.book;
                const comment: string | undefined = this.state.comment;
                const path: string = `/api/v1/knarkbok/events/`
                const url = EventBackendService.getUrl2(path);

                let payload;
                if (direction === KnarkbokType.move_neg) {
                    payload = {
                        "eventType": KnarkbokType[KnarkbokType.move_neg],
                        "bookId": book.bookId,
                        "amount": amount,
                        "newBalance": newBalance,
                        "destinationSource": remoteBook,
                        "comment": comment,
                        additionalData: this.state.additionalDetails
                    };
                } else {
                    payload = {
                        "eventType": KnarkbokType[KnarkbokType.move_pos],
                        "bookId": book.bookId,
                        "amount": amount,
                        "newBalance": newBalance,
                        "destinationSource": remoteBook,
                        "comment": comment,
                        additionalData: this.state.additionalDetails
                    };
                }

                let success: string = '';

                this.setState({
                    spinner: true,
                });

                await this.context.post(url, JSON.stringify(payload), {
                    success: success,
                    failure: ""
                })

                this.setState({
                    spinner: false,
                });

                if (forceSubmit) {
                    this.props.closeModal(book.bookId, true);
                } else {
                    this.props.closeModal()
                }
            } else {
                this.setState({displayWarning: true});
            }
        }
    }

    handleLocalCheck(e: React.ChangeEvent<HTMLInputElement>) {
        this.closeWarning();
        const stripped = e.currentTarget.value.replace(/\D/g, '');
        this.setState({newBalance: stripped})
    }

    handleMove(e: React.ChangeEvent<HTMLInputElement>) {
        this.closeWarning();
        const stripped = e.currentTarget.value.replace(/\D/g, '');
        this.setState({amount: stripped})
    }

    private flipComment(e: React.MouseEvent<HTMLButtonElement>): void {
        e.preventDefault();
        const showComment
            :
            boolean = !this.state.showComment;
        this.setState({
            showComment: showComment
        });
    }

    private handleChangeComment(e: React.ChangeEvent<HTMLTextAreaElement>) {
        const comment: string = e.currentTarget.value;
        this.setState({
            comment: comment
        });
    }

    private closeWarning() {
        this.setState({
            displayWarning: false
        });
    }

    private async handleDrugBookSelection(e: React.ChangeEvent<HTMLSelectElement>) {
        this.closeWarning();
        e.preventDefault();


        if (e.currentTarget.value === ADDITIONALINFOFLAG) {
            this.setState({showAdditionalDetails: true})
            return;
        } else {
            this.setState({showAdditionalDetails: false, additionalDetails: ""})
        }

        let binderId: number = +e.currentTarget.value;
        let drug: Drug = this.props.book.drug;
        const book: Book = await this.props.KBService.getBookByDrugBinder(binderId, drug);

        const wantedBinder: Binder | undefined = this.state.binders.find((binder) => binder.id.id === binderId);
        if (wantedBinder !== undefined) {
            this.setState({
                remoteBook: book.id,
                remoteLocationDisplayString: wantedBinder.locationName
            });
        }
    }

    tr(s: string) {
        return TranslationService.translation(s)
    }

    private closeModal() {
        this.props.closeModal();
    }

    private handleAdditionalDetails(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({additionalDetails: e.currentTarget.value})
    }
}

export default Move;
