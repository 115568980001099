import React, {Component} from "react";
import {BackendContext} from "../../../infra/BackendContext";
import {EventBackendService} from "../../../components/register/v2/EventBackendService";
import {Organisation} from "../../../components/model/Organisation";
import TranslationService from "../../../infra/TranslationService";
import translationService from "../../../infra/TranslationService";
import "./UserAdministration.css"

import DeleteLogo from "../../../images/delete-512.png"
import EditLogo from "../../../images/edit-225-225.png"
import CreateUserModal from "../../../components/create-user-modal/CreateUserModal";
import CreateUnregisteredStaffModal from "../../../components/create-user-modal/CreateUnregisteredStaffModal";
import {User} from "../../../components/model/User";
import {hasRight} from "../../../utils/HasRight";

export type UAU = Sortable & {
    userName: string,
    lastLogin?: string,
    email: string,
    phone: string,
    roles?: Role[]
};

export type Role = { name: string, roleId: string, description: string };

interface UAUResponse extends TageResponse {
    data: { users: UAU }
}

export type UnregisteredStaff = Sortable & {
    id: string,
}

export type Sortable = {
    firstName: string,
    lastName: string
}

interface UnregisteredStaffResponse extends TageResponse {
    data: { unregisteredStaffList: UnregisteredStaff[] }
}

export interface TageResponse {
    success: boolean,
    message: string
}

interface props {
    organisation?: Organisation,
    user: User
}

interface state {
    users: UAU[],
    unregisteredStaffList: UnregisteredStaff[],
    showUserModal: boolean,
    userEditing: boolean,
    userCreating: boolean,
    unregisteredStaffEditing: boolean,
    unregisteredStaffCreating: boolean,
    currentUnregisteredStaffId?: string,
    currentUserName?: string
    sortOrder?: string
}

enum SortOrder {
    FIRSTNAME = "firstName",
    LASTNAME = "lastName",
    STAFF_FIRSTNAME = "sFirstName",
    STAFF_LASTNAME = "sLastName",
    FIRSTNAMEREVERSE = "firstNameR",
    LASTNAMEREVERSE = "LastNameR",
    STAFF_FIRSTNAME_REVERSE = "sFirstNameR",
    STAFF_LASTNAME_REVERSE = "sLastNameR",
    EMAIL = "email",
    EMAILREVERSE = "emailR",
    LAST_LOGIN = "lastLogin",
    LAST_LOGIN_REVERSE = "lastLoginR",
}

function firstnameOrder(reverse: boolean) {
    return (a: Sortable, b: Sortable) => {
        const aCaps: string = a.firstName.toUpperCase();
        const bCaps: string = b.firstName.toUpperCase();
        if (aCaps < bCaps) {
            return -1 * (reverse ? -1 : 1);
        }
        if (aCaps > bCaps) {
            return 1 * (reverse ? -1 : 1);
        }
        return 0;
    };
}

function lastnameOrder(reverse: boolean) {
    return (a: Sortable, b: Sortable) => {
        const aCaps = a.lastName.toUpperCase();
        const bCaps = b.lastName.toUpperCase();
        if (aCaps < bCaps) {
            return -1 * (reverse ? -1 : 1);
        }
        if (aCaps > bCaps) {
            return 1 * (reverse ? -1 : 1);
        }
        return 0;
    };
}

function emailOrder(reverse: boolean) {
    return (a: UAU, b: UAU) => {
        const aCaps = a.email.toUpperCase();
        const bCaps = b.email.toUpperCase();
        if (aCaps < bCaps) {
            return -1 * (reverse ? -1 : 1);
        }
        if (aCaps > bCaps) {
            return 1 * (reverse ? -1 : 1);
        }
        return 0;
    };
}

function lastLoginOrder(reverse: boolean) {
    return (a: UAU, b: UAU) => {
        const aCaps = a.lastLogin;
        const bCaps = b.lastLogin;
        if (aCaps === undefined) return -1 * (reverse ? -1 : 1);
        if (bCaps === undefined) return 1 * (reverse ? -1 : 1);
        if (aCaps < bCaps) {
            return -1 * (reverse ? -1 : 1);
        }
        if (aCaps > bCaps) {
            return 1 * (reverse ? -1 : 1);
        }
        return 0;
    };
}

class UserAdministration extends Component<props, state> {
    static contextType = BackendContext;

    constructor(props: any) {
        super(props);

        this.state = ({
            showUserModal: false,
            userEditing: false,
            userCreating: false,
            unregisteredStaffCreating: false,
            unregisteredStaffEditing: false,
            users: [],
            unregisteredStaffList: []
        })
    }

    render() {
        const usersTable = this.userTable();
        const unregisteredStaffTable = this.staffTable();
        const {tr} = this;
        const {
            userEditing, userCreating, users, currentUserName,
            unregisteredStaffCreating, unregisteredStaffEditing, unregisteredStaffList, currentUnregisteredStaffId
        } = this.state;
        let userModal = <div></div>
        let staffModal = <div></div>

        if (userEditing || userCreating) {
            let user;
            if (userEditing) {
                user = users.find((usr) => usr.userName === currentUserName)
            }

            userModal = <CreateUserModal hideModal={() => this.hideUserModal()}
                                         user={user}
                                         editing={userEditing}
                                         organisation={this.props.organisation}
                                         updateUserList={() => this.updateUserList()}
                                         creating={userCreating}>
            </CreateUserModal>
        }

        if (unregisteredStaffEditing || unregisteredStaffCreating) {
            let unregisteredStaff;
            if (unregisteredStaffEditing) {
                unregisteredStaff = unregisteredStaffList.find((staff) => staff.id === currentUnregisteredStaffId)
            }
            staffModal = <CreateUnregisteredStaffModal hideModal={() => this.hideStaffModal()}
                                                       unregisteredStaff={unregisteredStaff}
                                                       editing={unregisteredStaffEditing}
                                                       organisation={this.props.organisation}
                                                       updateStaffList={() => this.updateUnregisteredStaffList()}
                                                       creating={unregisteredStaffCreating}>
            </CreateUnregisteredStaffModal>
        }

        const userAdministration: string = translationService.translation('user administration');
        const unregisteredStaffAdministration: string = translationService.translation('unregistered staff administration');
        let unregisteredStaffSection = <div></div>;
        if (hasRight(this.props.user, "administer-unregistered-staff", "unregistered staff administration")) {
            unregisteredStaffSection = <div>
                <div className={"row pt-4"}>
                    <div className={"col"}>
                        <h1>
                            {tr(unregisteredStaffAdministration)}
                        </h1>
                    </div>
                </div>
                <div className={"row pt-3"}>
                    <div className={"col-8"}>
                        <div className={"row m-0 p-0"}>
                            <div className={"col text-left pl-0"}>
                                <button
                                    onClick={() => this.createUnregisteredStaff()}
                                    aria-label={"Create new unregistered staff"}
                                    className={"btn btn-tage-default"}> {tr("Create staff with no account")}</button>
                            </div>
                        </div>
                    </div>
                </div>
                {unregisteredStaffTable}
            </div>;
        }

        return <div className={"container ua-container"}>
            <div className={"row pt-4"}>
                <div className={"col"}>
                    <h1>
                        {tr(userAdministration)}
                    </h1>
                </div>
            </div>
            <div className={"row pt-3"}>
                <div className={"col-8"}>
                    <div className={"row m-0 p-0"}>
                        <div className={"col text-left pl-0"}>
                            <button
                                onClick={() => this.createNewUser()}
                                aria-label={"Create new user"}
                                className={"btn btn-tage-default"}> {tr("Create new user")}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"row pt-4"}>
                <div className={"col"}>
                    <h3>
                        {tr("Current users:")}
                    </h3>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col uau-table"}>
                    {usersTable}
                </div>
            </div>
            {unregisteredStaffSection}
            {userModal}
            {staffModal}
        </div>
    }


    async componentDidMount() {
        await this.updateUserList();
        await this.updateUnregisteredStaffList();
    }

    userTable(): React.JSX.Element {
        const {tr} = this;
        let firstNameClass, lastNameClass, emailClass, lastLoginClass;
        firstNameClass = lastNameClass = emailClass = lastLoginClass = "btn btn-mini ml-1";
        let firstNameButtonSymbol, lastNameButtonSymbol, emailButtonSymbol, lastLoginButtonSymbol;
        firstNameButtonSymbol = lastNameButtonSymbol = emailButtonSymbol = lastLoginButtonSymbol = "▼";
        if (this.state) {
            const {users, sortOrder} = this.state
            if (users && users.length > 0) {
                let selected = " uaselected";
                let orderedUsers: UAU[] = users;

                if (sortOrder === SortOrder.FIRSTNAME || sortOrder === SortOrder.FIRSTNAMEREVERSE) {
                    firstNameButtonSymbol = sortOrder === SortOrder.FIRSTNAMEREVERSE ? "▼" : "▲"
                    firstNameClass = firstNameClass + selected;

                    orderedUsers = users.sort(firstnameOrder(sortOrder === SortOrder.FIRSTNAMEREVERSE));
                }

                if (sortOrder === SortOrder.LASTNAME || sortOrder === SortOrder.LASTNAMEREVERSE) {
                    lastNameButtonSymbol = sortOrder === SortOrder.LASTNAMEREVERSE ? "▼" : "▲"
                    lastNameClass = lastNameClass + selected;

                    orderedUsers = users.sort(lastnameOrder(sortOrder === SortOrder.LASTNAMEREVERSE));
                }

                if (sortOrder === SortOrder.EMAIL || sortOrder === SortOrder.EMAILREVERSE) {
                    emailButtonSymbol = sortOrder === SortOrder.EMAILREVERSE ? "▼" : "▲"
                    emailClass = emailClass + selected;

                    orderedUsers = users.sort(emailOrder(sortOrder === SortOrder.EMAILREVERSE));
                }

                if (sortOrder === SortOrder.LAST_LOGIN || sortOrder === SortOrder.LAST_LOGIN_REVERSE) {
                    lastLoginButtonSymbol = sortOrder === SortOrder.LAST_LOGIN_REVERSE ? "▼" : "▲"
                    lastLoginClass = lastLoginClass + selected;

                    orderedUsers = users.sort(lastLoginOrder(sortOrder === SortOrder.LAST_LOGIN_REVERSE));
                }

                return <table>
                    <thead>
                    <tr>
                        <th className={"col-nowrap"} style={{padding: 0}}>
                            {tr("firstName")}
                            <button onClick={() => this.sortByFirstName()}
                                    aria-label={"sort-by-firstname"}
                                    className={firstNameClass}>{firstNameButtonSymbol}
                            </button>
                        </th>
                        <th className={"col-nowrap"}>{tr("lastName")}
                            <button onClick={() => this.sortByLastName()}
                                    aria-label={"sort-by-lastname"}
                                    className={lastNameClass}>{lastNameButtonSymbol}
                            </button>
                        </th>
                        <th className={"col-nowrap"}>{tr("email")}
                            <button onClick={() => this.sortByEmail()}
                                    aria-label={"sort-by-email"}
                                    className={emailClass}>{emailButtonSymbol}
                            </button>
                        </th>
                        <th className={"col-nowrap"}>{tr("lastLogin")}
                            <button onClick={() => this.sortByLastLogin()}
                                    aria-label={"sort-by-lastLogin"}
                                    className={lastLoginClass}>{lastLoginButtonSymbol}
                            </button>
                        </th>
                        <th>{tr("edit")}</th>
                        <th>{tr("delete")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {orderedUsers.map((user: UAU, index: number) => {
                        return <tr key={index + "table_row"} className={index % 2 === 0 ? "grayed" : ""}>
                            <td>{user.firstName}</td>
                            <td>{user.lastName}</td>
                            <td>{user.email}</td>
                            <td className={"col-nowrap"}>{user.lastLogin}</td>
                            <td>
                                <button aria-label={"edit-user"}
                                        className={"btn btn-invis-bg pl-1 b-0"}
                                        onClick={() => this.handleEdit(user)}>
                                    <img
                                        height={18}
                                        src={EditLogo}
                                        alt={"altText"}/>
                                </button>
                            </td>
                            <td>
                                <button aria-label={"delete-user"}
                                        className={"btn btn-invis-bg pl-1 b-0"}
                                        onClick={() => this.handleDelete(user)}>
                                    <img
                                        height={18}
                                        src={DeleteLogo}
                                        alt={"altText"}/>
                                </button>
                            </td>
                        </tr>
                    })}
                    </tbody>
                </table>;
            }
        }
        return <div>
        </div>
    }

    staffTable(): React.JSX.Element {
        const {tr} = this;
        let staffFirstNameClass, staffLastNameClass;
        staffFirstNameClass = staffLastNameClass = "btn btn-mini ml-1";
        let staffFirstNameButtonSymbol, staffLastNameButtonSymbol;
        staffFirstNameButtonSymbol = staffLastNameButtonSymbol = "▼";
        if (this.state) {
            const {sortOrder, unregisteredStaffList} = this.state

            if (unregisteredStaffList !== undefined && unregisteredStaffList.length > 0) {

                let selected = " uaselected";
                let orderedStaff: UnregisteredStaff[] = unregisteredStaffList;

                if (sortOrder === SortOrder.STAFF_FIRSTNAME || sortOrder === SortOrder.STAFF_FIRSTNAME_REVERSE) {
                    staffFirstNameButtonSymbol = sortOrder === SortOrder.STAFF_FIRSTNAME_REVERSE ? "▼" : "▲"
                    staffFirstNameClass = staffFirstNameClass + selected;

                    orderedStaff = unregisteredStaffList.sort(firstnameOrder(sortOrder === SortOrder.STAFF_FIRSTNAME_REVERSE));
                }

                if (sortOrder === SortOrder.STAFF_LASTNAME || sortOrder === SortOrder.STAFF_LASTNAME_REVERSE) {
                    staffLastNameButtonSymbol = sortOrder === SortOrder.STAFF_LASTNAME_REVERSE ? "▼" : "▲"
                    staffLastNameClass = staffLastNameClass + selected;

                    orderedStaff = unregisteredStaffList.sort(lastnameOrder(sortOrder === SortOrder.STAFF_LASTNAME_REVERSE));
                }

                return <div>
                    <div className={"row pt-4"}>
                        <div className={"col"}>
                            <h3>
                                {tr("Current staff with no account:")}
                            </h3>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col uau-table"}>
                            <table>
                                <thead>
                                <tr>
                                    <th style={{padding: 0}}>
                                        {tr("firstName")}
                                        <button onClick={() => this.sortByStaffFirstName()}
                                                aria-label={"sort-by-staff_firstname"}
                                                className={staffFirstNameClass}>{staffFirstNameButtonSymbol}
                                        </button>
                                    </th>
                                    <th>{tr("lastName")}
                                        <button onClick={() => this.sortByStaffLastName()}
                                                aria-label={"sort-by-staff_lastname"}
                                                className={staffLastNameClass}>{staffLastNameButtonSymbol}
                                        </button>
                                    </th>
                                    <th>{tr("edit")}</th>
                                    <th>{tr("delete")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {orderedStaff.map((unregisteredStaff: UnregisteredStaff, index: number) => {
                                    return <tr key={index + "staff_table_row"}
                                               className={index % 2 === 0 ? "grayed" : ""}>
                                        <td>{unregisteredStaff.firstName}</td>
                                        <td>{unregisteredStaff.lastName}</td>
                                        <td>
                                            <button aria-label={"edit-staff"}
                                                    className={"btn btn-invis-bg pl-1 b-0"}
                                                    onClick={() => this.handleUnregisteredStaffEdit(unregisteredStaff)}>
                                                <img
                                                    height={18}
                                                    src={EditLogo}
                                                    alt={"altText"}/>
                                            </button>
                                        </td>
                                        <td>
                                            <button aria-label={"delete-staff"}
                                                    className={"btn btn-invis-bg pl-1 b-0"}
                                                    onClick={() => this.handleUnregisteredStaffDelete(unregisteredStaff)}>
                                                <img
                                                    height={18}
                                                    src={DeleteLogo}
                                                    alt={"altText"}/>
                                            </button>
                                        </td>
                                    </tr>
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
        }

        return <div>
        </div>
    }


    private tr(string: string): string {
        return TranslationService.translation(string)
    }

    private handleEdit(user: UAU) {
        this.setState({userEditing: true, currentUserName: user.userName})
        return undefined;
    }

    private async handleDelete(user: UAU) {
        const {tr} = this;
        let orgId: string = this.props.organisation?.organisationId || "0";
        const path: string = `/api/v1/administration/user/${user.userName}/${orgId}`
        const url: string = EventBackendService.getUrl2(path);

        if (window.confirm(tr("This will delete user:") + user.firstName + " " + user.lastName)) {
            const deleteResult = await this.context.delete(url, {
                success: "",
                failure: this.tr("Failed to delete user")
            }) as TageResponse;

            if (deleteResult.success) {
                await this.updateUserList();
            }
        }
    }

    private createNewUser() {
        this.setState({userCreating: true})
    }

    private createUnregisteredStaff() {
        this.setState({unregisteredStaffCreating: true})
    }

    private hideUserModal() {
        this.setState({userEditing: false, userCreating: false, currentUserName: undefined})
    }

    private hideStaffModal() {
        this.setState({
            unregisteredStaffEditing: false,
            unregisteredStaffCreating: false,
            currentUnregisteredStaffId: undefined
        })
    }

    private sortByFirstName() {
        if (this.state.sortOrder === SortOrder.FIRSTNAME) {
            this.setState({sortOrder: SortOrder.FIRSTNAMEREVERSE})
        } else {
            this.setState({sortOrder: SortOrder.FIRSTNAME})
        }
    }

    private sortByLastName() {
        if (this.state.sortOrder === SortOrder.LASTNAME) {
            this.setState({sortOrder: SortOrder.LASTNAMEREVERSE})
        } else {
            this.setState({sortOrder: SortOrder.LASTNAME})
        }
    }

    private sortByStaffFirstName() {
        if (this.state.sortOrder === SortOrder.STAFF_FIRSTNAME) {
            this.setState({sortOrder: SortOrder.STAFF_FIRSTNAME_REVERSE})
        } else {
            this.setState({sortOrder: SortOrder.STAFF_FIRSTNAME})
        }
    }

    private sortByStaffLastName() {
        if (this.state.sortOrder === SortOrder.STAFF_LASTNAME) {
            this.setState({sortOrder: SortOrder.STAFF_LASTNAME_REVERSE})
        } else {
            this.setState({sortOrder: SortOrder.STAFF_LASTNAME})
        }
    }

    private sortByEmail() {
        if (this.state.sortOrder === SortOrder.EMAIL) {
            this.setState({sortOrder: SortOrder.EMAILREVERSE})
        } else {
            this.setState({sortOrder: SortOrder.EMAIL})
        }
    }

    private sortByLastLogin() {
        if (this.state.sortOrder === SortOrder.LAST_LOGIN) {
            this.setState({sortOrder: SortOrder.LAST_LOGIN_REVERSE})
        } else {
            this.setState({sortOrder: SortOrder.LAST_LOGIN})
        }
    }

    private async updateUserList() {
        let orgId: string = this.props.organisation?.organisationId || "0";
        const path: string = `/api/v1/administration/user`
        const url: string = EventBackendService.getUrl2(path);

        const response: any = await this.context.getWithOrg(url, orgId, {
            success: "",
            failure: ""
        }) as UAUResponse;

        if (response && response.success) {
            this.setState({users: response.data.users})
        }
    }

    private async updateUnregisteredStaffList() {
        let orgId: string = this.props.organisation?.organisationId || "0";
        const path: string = `/api/v1/administration/unregistered-staff`
        const url: string = EventBackendService.getUrl2(path);

        const response: any = await this.context.getWithOrg(url, orgId, {
            success: "",
            failure: ""
        }) as UnregisteredStaffResponse;

        if (response && response.success) {
            this.setState({unregisteredStaffList: response.data})
        }
    }

    private handleUnregisteredStaffEdit(unregisteredStaff: UnregisteredStaff) {
        this.setState({unregisteredStaffEditing: true, currentUnregisteredStaffId: unregisteredStaff.id})
        return undefined;
    }

    private async handleUnregisteredStaffDelete(unregisteredStaff: UnregisteredStaff) {
        const {tr} = this;
        const path: string = `/api/v1/administration/unregistered-staff/${unregisteredStaff.id}`
        const url: string = EventBackendService.getUrl2(path);

        if (window.confirm(tr("This will delete unregistered staff:") + unregisteredStaff.firstName + " " + unregisteredStaff.lastName)) {
            const deleteResult = await this.context.delete(url, {
                success: "",
                failure: this.tr("Failed to delete unregistered staff")
            }) as TageResponse;

            if (deleteResult.success) {
                await this.updateUnregisteredStaffList();
            }
        }

    }
}

export default UserAdministration;
