import {User} from "./SearchUser";
import React from "react";
import {EventBackendService} from "../../register/v2/EventBackendService";
import {TageResponse} from "../../../pages/administration/user/UserAdministration";
import {BackendContext, BackendInterface} from "../../../infra/BackendContext";
import {OrganisationRoles} from "../roles/v3/OrganisationRoles";
import {Role} from "../roles/v3/Role";
import {uuidv4} from "../../register/v2/Uuid";
import EditLogo from "../../../images/edit-225-225.png"
import EditOrganisationRolesModal from "./EditOrganisationRolesModal";
import {Organisation} from "../roles/v3/Organisation";

interface props {
    organisationId: string | undefined;
    user: User
}

interface state {
    organisationRoles?: OrganisationRoles,
    organisationRolesEditing: boolean,
    editingOrganisation: Organisation | undefined,
    editingCurrentRoles: Role[],
}

export interface OrganisationRolesResponse extends TageResponse {
    data: OrganisationRoles
}

class UserOrganisationRolesView extends React.Component<props, state> {
    static contextType: React.Context<BackendInterface> = BackendContext;

    constructor(props: any) {
        super(props);
        this.state = {
            organisationRoles: undefined,
            organisationRolesEditing: false,
            editingOrganisation: undefined,
            editingCurrentRoles: [],
        };
    }

    render() {
        let editOrganisationModal = <div></div>
        if (this.state.editingOrganisation) {
            editOrganisationModal = <EditOrganisationRolesModal
                hideModal={() => this.hideModal()}
                user={this.props.user}
                organisation={this.state.editingOrganisation}
                currentOrganisationId={this.props.organisationId}
                updateOrganisationRoleList={() => this.populateOrganisationRoles()}
                currentRoles={this.state.editingCurrentRoles}>
            </EditOrganisationRolesModal>
        }

        return (
            <div>
                {editOrganisationModal}
                <div className={"pt-4"}>
                    <div className={"user-border"}>
                        <div className={"row pt-2 pl-4 card-h"}>
                            Organisations
                        </div>
                        <div className={"row pt-2 pl-4"}>
                            <button aria-label="Create new user" className="btn user-overview-button">Add Organisation
                            </button>
                        </div>
                        <div className={"pt-2"}>
                            {this.getUserOrganisations()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async componentDidMount() {
        await this.populateOrganisationRoles();
    }

    private async populateOrganisationRoles() {
        const userName: string = this.props.user.userName;
        const path: string = '/api/v3/backoffice/user/organisations/roles/' + userName;
        const url: string = EventBackendService.getUrl2(path);
        const currentOrganisations: Promise<OrganisationRolesResponse> = this.context.getWithOrg(url, this.props.organisationId);
        if (currentOrganisations !== undefined) {
            currentOrganisations.then((organisationsResponse) => {
                if (organisationsResponse.success) {
                    this.setState({
                        organisationRoles: organisationsResponse.data
                    })
                }
            });
        }
    }

    private getUserOrganisations(): any {
        let organisationRoles = this.state.organisationRoles;
        if (organisationRoles !== undefined && organisationRoles.organisations.length > 0) {
            return organisationRoles.organisations.map((userOrganisation: any) => {
                let editButton = <button aria-label={"edit-user"}
                                         className={"btn btn-invis-bg pl-1 mt-0 pt-0"}
                                         onClick={() => this.handleEdit(userOrganisation.organisation, userOrganisation.roles)}>
                    <img
                        height={18}
                        src={EditLogo}
                        alt={"altText"}/>
                </button>
                return <div className={"pt-2"} key={userOrganisation.organisation.id}>
                    <div className={"pt-2 mt-2 pl-2 organisation-border"}>
                        <div className={"strong"}>{userOrganisation.organisation.name}{editButton}</div>
                        {this.getRoles(userOrganisation.roles)}
                    </div>
                </div>

            })
        }
    }

    private getRoles(roles: Role[]): any {
        if (roles !== undefined && roles.length > 0) {
            return roles.map((role: Role) => {
                return <div className={"pt-1 pl-2"} key={role.id + uuidv4()}>
                    {role.name}
                </div>
            })
        }
    }

    private handleEdit(organisation: Organisation, roles: Role[]) {
        this.setState({organisationRolesEditing: true, editingOrganisation: organisation, editingCurrentRoles: roles})
    }

    private hideModal() {
        this.setState({organisationRolesEditing: false, editingOrganisation: undefined, editingCurrentRoles: []})

    }
}

export default UserOrganisationRolesView;
