import React from 'react';
import {ModalDisplayBinder} from "../KnarkbokModal";
import {KnarkbokService} from "../Knarkbokservice";
import {BookId, KnarkbokType, Partials} from "../models/Models";
import "./Move.css";
import {EventBackendService} from "../../register/v2/EventBackendService";
import {BackendContext, BackendInterface} from "../../../infra/BackendContext";
import UserSessionUtilService from "../../model/UserSessionUtilService";
import {Resolution} from "../../../infra/Constants";
import TranslationService from "../../../infra/TranslationService";
import {Organisation} from "../../model/Organisation";

interface props {
    closeModal: (bookId?: BookId, sentEmailWarning?: boolean) => void;
    book: ModalDisplayBinder;
    KBService: KnarkbokService;
    isDruglord: boolean;
    organisation: Organisation;
    partials?: Partials[]
}

interface state {
    spinner: boolean,
    newBalance: string | undefined,
    displayCheckWarning: boolean
    comment: string | undefined;
    showComment: boolean;
    partials?: Partials[],
    partialsInventory?: Partials[]
}

class Check extends React.Component<props, state> {
    static contextType: React.Context<BackendInterface> = BackendContext;


    constructor(props: any) {
        super(props);

        let partialsInventory;
        let partials: Partials[] = [];
        if (this.props.partials) {
            this.props.partials.forEach((part) => {
                partials.push({name: part.name, inventory: ""})
            })

            partialsInventory = JSON.parse(JSON.stringify(this.props.partials))
        }

        this.state = {
            spinner: false,
            newBalance: "",
            displayCheckWarning: false,
            comment: undefined,
            showComment: false,
            partialsInventory: partialsInventory,
            partials: partials
        }
    }

    render() {
        if (this.state.spinner) {
            return this.getSpinner()
        }
        const type = UserSessionUtilService.getResolution() === Resolution.Desktop ? "text" : "number"
        let partials: any[];
        partials = [];


        if (this.state.partials && this.state.partials.length > 0) {

            partials.push(<div key={"asdf"} className={"row pt-1"}>
                <div className={"col"}></div>
                <div className={"col-6 m-0 p-0"}>
                    {this.tr("Öppnade flaskor (vikt med kork):")}
                </div>
                <div className={"col"}></div>
            </div>)

            this.state.partials.map((p: Partials) => {
                partials.push(<div className={"row pt-1"} key={p.name}>
                    <div className={"col"}></div>
                    <div className={"col-6 m-0 p-0"}>
                        <div className={"row m-0 p-0"}>
                            <div className={"col m-0 p-0"}>
                                {p.name}
                            </div>
                            <div className={"col m-0 p-0 text-right"}>
                                <input value={p.inventory} className={"mr-0"} size={4}
                                       onChange={(e) => this.partialInventory(e, p.name)}/>
                            </div>
                            <div className={"pl-1"}>
                                g
                            </div>
                        </div>
                    </div>
                    <div className={"col"}></div>
                </div>)

                return null; //TODO switch to foreach
            })
        }

        const commentFlip = <div
            aria-label={'show hide comment'}
            className={"pt-2"}
            title="Lägg till kommentar">
            <button className={"btn btn-tiny-expand"} onClick={(e) => this.flipComment(e)}>
                {this.state.showComment ? "-" : "+"}
            </button>
        </div>;

        const commentField = <div>
            <div className={"row m-0 pb-1 pt-2"}>
                <div className={"pt-2 pr-2"}>Kommentar:</div>
                {commentFlip}
            </div>
            {
                this.state.showComment ? <React.Fragment>
                    <div className={"row m-0 p-0"}>
                        <textarea value={this.state.comment}
                                  onChange={(e) => this.handleChangeComment(e)}
                                  className={"max-size-input m-0"}
                        />
                    </div>
                </React.Fragment> : <div/>
            }
        </div>
        //TODO this part is repeated in all innermodals, and as such should be outsourced
        const warningStuff = this.state.displayCheckWarning ? <div>
            <div className={"row m-0 p-0 pb-3 justify-content-center"}>
                <h5 className={"innermodal-warning-text"}>Är du säker på att antalet är korrekt?</h5>
            </div>
            <div className={"row m-0 pr-4 justify-content-end"}>
                <button className={"btn btn-red"} onClick={() => this.submit(true)}>Jag är säker</button>
                <button className={"btn btn-tage-default-cancel  ml-2 mr-2"}
                        onClick={() => this.closeWarning()}>Avbryt
                </button>
            </div>
        </div> : "";

        const submitCancelButtons = this.state.displayCheckWarning ? "" :
            <div className={"row m-0 pr-4 pt-2 justify-content-end"}>
                <button aria-label={'ok'}
                        disabled={!(this.state.newBalance || this.state.comment) || !this.partialsFilledIn()}
                        className={"btn btn-tage-default"}
                        onClick={() => this.submit()}>
                    OK
                </button>
                <button aria-label={'cancel'}
                        className={"btn btn-tage-default-cancel ml-2 mr-2"}
                        onClick={() => this.closeModal()}>
                    Avbryt
                </button>
            </div>;

        return (
            <div className={"container m-0 p-0"}>
                <div className={"row"}>
                    <div className={"col"}></div>
                    <div className={"col-6 m-0 p-0"}>
                        <div className={"row m-0 pb-1"}>
                            <div className={"col m-0 p-0"}>
                                {this.tr("Nytt saldo:")}
                            </div>
                        </div>
                        <div className={"row m-0 pb-1"}>
                            <div className={"col m-0 p-0"}>
                                <input aria-label={'new balance'}
                                       value={this.state.newBalance}
                                       className={"max-size-input p-0"}
                                       onChange={(e) => this.handleCheck(e)}
                                       type={type}/>
                            </div>
                        </div>
                    </div>
                    <div className={"col"}></div>
                </div>
                {partials}
                <div className={"row"}>
                    <div className={"col"}></div>
                    <div className={"col-6 m-0 p-0"}>
                        {commentField}
                    </div>
                    <div className={"col"}></div>
                </div>
                {warningStuff}
                {submitCancelButtons}
            </div>
        );
    }

    private getSpinner() {
        return <div aria-label={"administer-drug-spinner"}
                    className={"spinner"}/>;
    }

    private submit = async (forceSubmit?: boolean) => {
        if (this.props.book?.bookId) {
            const isDrugLord: boolean = this.props.isDruglord;
            const newBalance: string | undefined = this.state.newBalance;
            const oldInventory: number = this.props.book.inventory;
            const check: boolean = this.props.KBService.aboutToSubmitCheck(oldInventory, newBalance + '', KnarkbokType.check);
            const partialCheck: boolean = this.partialCheck();

            if ((check && partialCheck) || forceSubmit || isDrugLord) {
                let type: KnarkbokType = KnarkbokType.check;
                if (isDrugLord) {
                    type = KnarkbokType.druglord_check;
                }
                const book: ModalDisplayBinder = this.props.book;
                const comment: string | undefined = this.state.comment;
                let path;
                let url;
                let payload;

                if (this.props.partials && this.props.partials.length > 0) {
                    path = `/api/v1/knarkbok/partialevents/`
                    url = EventBackendService.getUrl2(path);

                    const partials: any[] = [];
                    if (this.state.partials) {
                        this.state.partials.forEach((part) => {
                            partials.push({
                                bottleLabel: part.name,
                                weightAfter: part.inventory,
                                weightBefore: part.inventory,
                                discardUsed: false
                            })
                        })
                    }

                    payload = {
                        eventType: KnarkbokType[type],
                        bookId: book.bookId,
                        drugId: {id: book.drug.id?.id},
                        binderId: {id: book.binderId.id},
                        organisationId: this.props.organisation.organisationId,
                        amount: 0,
                        newBalance: newBalance,
                        comment: comment,
                        partials: partials
                    };
                } else {
                    path = `/api/v1/knarkbok/events/`
                    url = EventBackendService.getUrl2(path);

                    payload = {
                        eventType: KnarkbokType[type],
                        bookId: book.bookId,
                        amount: 0,
                        newBalance: newBalance,
                        comment: comment
                    };
                }

                let success: string = '';

                this.setState({
                    spinner: true,
                });

                await this.context.post(url, JSON.stringify(payload), {
                    success: success,
                    failure: ""
                })

                this.setState({
                    spinner: false,
                });

                if (forceSubmit) {
                    this.props.closeModal(book.bookId, true);
                } else {
                    this.props.closeModal()
                }
            } else {
                this.setState({displayCheckWarning: true});
            }
        }
    }

    handleCheck(e: React.ChangeEvent<HTMLInputElement>) {
        this.closeWarning();
        const stripped = e.currentTarget.value.replace(/\D/g, '');
        this.setState({newBalance: stripped})
    }


    private closeWarning() {
        this.setState({displayCheckWarning: false})
    }

    private flipComment(e: React.MouseEvent<HTMLButtonElement>): void {
        e.preventDefault();
        this.setState({showComment: !this.state.showComment})
    }

    private handleChangeComment(e: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({comment: e.currentTarget.value});
    }

    private closeModal() {
        this.props.closeModal()
    }

    private tr(str: string): string {
        return TranslationService.translation(str)
    }

    private partialInventory(e: React.ChangeEvent<HTMLInputElement>, name: string) {
        const partials = this.state.partials;
        const wanted = partials?.find((p) => p.name === name)

        if (wanted) {
            wanted.inventory = e.currentTarget.value;
            this.setState({partials})
        }
    }

    private partialsFilledIn() {
        if (this.state.partials && this.state.partials.length > 0) {
            for (let i = 0; i < this.state.partials.length; i++) {
                if (!this.state.partials[i].inventory) {
                    return false;
                }
            }

        }
        return true;
    }

    private partialCheck(): boolean {
        if (this.state.partials && this.state.partials.length > 0) {
            for (let i = 0; i < this.state.partials.length; i++) {
                const partial = this.state.partials[i];
                const currentInv = +partial.inventory;
                const wantedInventory = this.state.partialsInventory?.find((p) => partial.name === p.name);

                if (wantedInventory) {
                    if (+wantedInventory.inventory !== currentInv) { //this should maybe be +/- 3g
                        return false;
                    }
                } else {
                    return false;
                }

            }
        }

        return true;


    }
}

export default Check;
