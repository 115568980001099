import {User} from "../components/model/User";
import {Right} from "../components/model/Right";

export function hasRight(user: User, rightName: string, rightType: string) {
    let rights: Right[] = user.rights;
    if (!rights || rights.length < 0) return false;
    for (let right of rights) {
        if ((right.type === rightType && right.name === rightName)) {
            return true;
        }
    }
    return false;
}
