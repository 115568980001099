import React from 'react';
import {BackendContext} from "../../infra/BackendContext";
import {Organisation} from "../model/Organisation";
import {User} from "../model/User";
import OldBackoffice from "./OldBackoffice";
import ManageUsers from "./users/ManageUsers";

interface props {
    action: string | undefined,
    organisation?: Organisation,
    user: User,
    refreshExpiredToken: () => void,
    securityToken: string
}

interface state {
}

class BackofficeLanding extends React.Component<props, state> {
    static contextType = BackendContext;

    constructor(props: any) {
        super(props);
        this.state = {}
    }

    render() {
        const organisation: Organisation | undefined = this.props.organisation;

        let action = this.props.action;
        if (action) {
            switch (action) {
                case "backoffice" :
                    return <OldBackoffice
                        user={this.props.user}
                        refreshExpiredToken={this.props.refreshExpiredToken}
                        securityToken={this.props.securityToken}
                    />;
                case "manage users" :
                    return <ManageUsers
                        organisationId={organisation?.organisationId}
                    />;
            }
        }

        return <div>
            Select something from the menu
        </div>;
    }
}

export default BackofficeLanding;
