import React, {ReactNode} from "react";
import Login from "./Login";
import "./LoginMobile.css"
import TranslationService from "../../infra/TranslationService";


class LoginMobile extends Login {
    render(): ReactNode {
        const {domain} = this.props;

        const emailInput = this.getEmailInput(18);
        const passwordInput = this.getPasswordInput(18, false);
        const loginButton = this.getLoginButton();
        const showPasswordButton = this.getShowPasswordButton("btn btn-revealPassword pl-2 pr-2 pt-0 pb-1 border-0");
        const errorMessage = this.getErrorMessage();
        const passwordRecoveryLink = this.getPasswordRecoveryLink("navbar-forgotten-link-mobile");

        return <div className={"container"}>
            <div className={"row p-0 m-0"}>
                <div className={"col-12 pl-0 pr-0"}>
                    <div className={"justify-content-center pt-3 pb-2 loginMobileBox mb-5 mt-1"}>
                        <div className={"row justify-content-center"}>
                            <div className={"col-3 text-right pr-0 pl-3"}>
                                {TranslationService.translatePerDomain(this.emailPlaceholder, domain)}:
                            </div>
                            <div className={"col-9 pl-1 pr-2"}>
                                {emailInput}
                            </div>
                        </div>
                        <div className={"row justify-content-center pt-1"}>
                            <div className={"col-3 text-right pr-0 pl-3"}>
                                {TranslationService.translatePerDomain(this.passwordPlaceholder, domain)}:
                            </div>
                            <div className={"col-9 pl-1 pr-2"}>
                                {passwordInput}
                                {showPasswordButton}
                            </div>
                        </div>

                        <div className={"row justify-content-end pt-3 pr-4"}>
                            {loginButton}
                        </div>

                        <div className={"row pt-5 m-0"}>
                            <div className={"col-3 p-0 m-0"}/>
                            <div className={"col-9 justify-content-center m-0"}> {passwordRecoveryLink}</div>
                        </div>

                        <div className={"row justify-content-center"}>
                            {errorMessage}
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default LoginMobile;
