import React from "react";
import "./KnarkbokModal.css"
import "./ToggleSwitch.css"
import "./Knarkbokuserpage.css"
import {KnarkbokService} from "./Knarkbokservice";
import DrugBook from "./DrugBook";
import {withRouter} from "react-router-dom";

interface props {
    KBService: KnarkbokService,
    testing?: boolean,
    match: {
        path: string,
        url: string,
        isExact: boolean
        params: { drugBook: string }
    }
}

interface state {
}

class DrugBookRouter extends React.Component<props, state> {
    constructor(props: any) {
        super(props);

        this.state = {};
    }

    render(): React.JSX.Element {
        const kbService: KnarkbokService = this.props.KBService;
        const drugBook: string = this.props.match.params.drugBook;

        return <DrugBook aria-label={'drug book router'}
                         testing={this.props.testing}
                         KBService={kbService}
                         drugBook={drugBook}
        />;
    }
}

export default withRouter(DrugBookRouter);
