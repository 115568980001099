import React from 'react';
import './SearchBar.css'
import TranslationService from "../../infra/TranslationService";

interface props {
    handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
    resetSearch: () => void
    searchArgument: string
    classNames?: string
}

class SearchBar extends React.Component<props> {
    render(): React.JSX.Element {
        const {tr} = this;
        let className = "search-user-drugbook " +this.props.classNames;
        return <div>
            <div className={"row m-0 p-0 input-wrapper"}>
                <input className={className}
                       aria-label={"search-field"}
                       placeholder={tr("Filter")}
                       onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.handleSearch(e)}
                       type={"text"} value={this.props.searchArgument}/>
                <div>
                    <button onClick={() => this.props.resetSearch()}
                            className={"btn btn-tage-search-reset"}>{tr("X")}</button>
                </div>
            </div>
        </div>;
    }

    private tr(str: string): string {
        return TranslationService.translation(str)
    }
}

export default SearchBar;
