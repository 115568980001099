import React from 'react';
import {KnarkbokService} from "./Knarkbokservice";
import "./DrugEventFocus.css"
import {BinderId, BookId, DrugEvent, DrugId, KnarkbokType} from "./models/Models";
import TranslationService from "../../infra/TranslationService";
import {jsPDF} from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";

interface props {
    KBService: KnarkbokService,
    closeFocus: () => void,
    drugId: DrugId | undefined
    binderId: BinderId | undefined
    book: BookId | undefined
    drugName: string,
    locationName: string
}

interface state {
    events: DrugEvent[],
    partials: PartialEvents[]
}

interface PartialEvents {
    bookLabel: string
    details: DrugEvent[]
}

class DrugEventFocus extends React.Component<props, state> {
    constructor(props: any) {
        super(props);
        this.state = {
            events: [],
            partials: []
        }
    }

    async componentDidMount() {
        const drugId: DrugId | undefined = this.props.drugId;
        const binderId: BinderId | undefined = this.props.binderId;
        const bookId: BookId | undefined = this.props.book;
        if (drugId && binderId && bookId) {
            let eventLogs = await this.props.KBService.getEventLogForDrug(drugId, binderId) as any; //TODO type

            if (eventLogs && eventLogs.books.length > 0) {
                const events = eventLogs.books[0];

                let partials = [];
                let booksLength = eventLogs.books.length;
                if (eventLogs.books && booksLength > 1) {
                    let index = 1;
                    for (index; index <= booksLength; index++) {
                        partials.push(eventLogs.books[index]);
                    }
                }

                this.setState({
                    events: events.details.reverse(),
                    partials: partials
                })
            }
        }
    }

    async componentDidUpdate(prevProps: Readonly<props>, prevState: Readonly<state>, snapshot?: any) {
        const drugId = this.props.drugId;
        const binderId = this.props.binderId;
        const bookId = this.props.book;
        if (
            drugId && prevProps.drugId?.id !== drugId?.id &&
            binderId && prevProps.binderId?.id !== binderId?.id &&
            bookId && prevProps.book?.id !== bookId?.id
        ) {
            let events = await this.props.KBService.getEventLogForDrug(drugId, binderId) as any;
            this.setState({events: events.details.reverse()});
        }
    }

    render() {
        let data: any[] = []
        if (this.state.events) {
            data.push(
                <tr key="header-thing">
                    <th>Datum</th>
                    <th>Användare</th>
                    <th>Händelse</th>
                    <th>Specifikation</th>
                    <th>Kommentar</th>
                    <th>Antal</th>
                    <th>Räknat saldo</th>
                    <th>Beräknat saldo</th>
                    <th>Avvikelse</th>
                    <th>Epost skickat</th>
                </tr>
            )

            this.state.events.forEach((ev: DrugEvent, index) => {
                const redWarning = ev.warning ? " redwarning" : ""
                const comment: string | undefined = ev.comment;

                let commentStart: string = "";
                if (comment) {
                    const length: number = Math.min(7, comment.length);
                    const commentText: string = comment.substr(0, length);
                    let more: string = '';
                    if (comment.length > length) {
                        more = ' ...';
                    }
                    commentStart = commentText + more;
                }

                data.push(
                    <tr key={"drug-event" + index} className={redWarning}>
                        <td>{ev.time}</td>
                        <td>{ev.userFullName}</td>
                        <td>{TranslationService.translation(ev.event.toString())}</td>
                        <td>{ev.additionalData ? TranslationService.translation(ev.additionalData) : ""}</td>
                        <td>
                            {comment ? (
                                <div title={comment} className={"hover-pointer"}
                                     onClick={(e) => DrugEventFocus.popup(e, comment)}>
                                    {commentStart}
                                </div>
                            ) : ""}
                        </td>
                        <td>{ev.amount}</td>
                        <td>{ev.reportedTotal}</td>
                        <td>{ev.trueTotal}</td>
                        <td>{ev.balance}</td>
                        <td>{ev.warning ? "✓" : ""}</td>
                    </tr>
                )
            })
        }

        if (this.state.partials) {
            this.state.partials.forEach((partial, index) => {
                if (partial) {
                    const key = "header-thing-" + index
                    data.push(<div key={key + " f"} className={"row pt-3"}></div>)

                    const bookLabel: string = "Öppnad flaska, " + partial.bookLabel;
                    data.push(<div key={key + " d"} className={"tr pt-3"}>
                        <h5>{bookLabel}</h5>
                    </div>)

                    data.push(<div key={key + " e"} className={"row pt-3"}></div>)
                    data.push(<tr key={key}>
                        <th>Datum</th>
                        <th>Användare</th>
                        <th>Händelse</th>
                        <th>Specifikation</th>
                        <th>Kommentar</th>
                        <th>Mängd</th>
                        <th>Vikt före</th>
                        <th>Vikt efter</th>
                        <th>Avvikelse (g)</th>
                        <th>Epost skickat</th>
                    </tr>)

                    partial.details.reverse()

                    partial.details.forEach((ev: DrugEvent, index) => {
                        const redWarning = ev.warning ? " redwarning" : ""
                        const comment: string | undefined = ev.comment;

                        let commentStart: string = "";
                        if (comment) {
                            const length: number = Math.min(7, comment.length);
                            const commentText: string = comment.substr(0, length);
                            let more: string = '';
                            if (comment.length > length) {
                                more = ' ...';
                            }
                            commentStart = commentText + more;
                        }

                        const eventType: KnarkbokType = ev.event;
                        const eventTypeName: string = TranslationService.translation(eventType.toString());

                        let weightBefore: number | undefined = ev.weightBefore;
                        let weightBeforeStr: string = '';

                        if (eventType !== KnarkbokType.created && weightBefore !== undefined && weightBefore !== 0) {
                            weightBeforeStr = '' + weightBefore;
                        }

                        let completeComment: React.JSX.Element | string;
                        if (comment) {
                            completeComment = <div title={comment} className={"hover-pointer"}
                                                   onClick={(e) => DrugEventFocus.popup(e, comment)}>
                                {commentStart}
                            </div>;
                        } else {
                            completeComment = "";
                        }

                        data.push(
                            <tr key={"drug-event" + key + index} className={redWarning}>
                                <td>{ev.time}</td>
                                <td>{ev.userFullName}</td>
                                <td>{eventTypeName}</td>
                                <td>{ev.additionalData ? TranslationService.translation(ev.additionalData) : ""}</td>
                                <td>{completeComment}</td>
                                <td>{ev.amount}</td>
                                <td>{weightBeforeStr}</td>
                                <td>{ev.trueTotal}</td>
                                <td>{ev.balance}</td>
                                <td>{ev.warning ? "✓" : ""}</td>
                            </tr>
                        )
                    })
                }
            })
        }


        const grid: React.JSX.Element = (
            <div aria-label={"Drug Book Modal"} className={"container2 m-0 pl-0 pr-0"}>
                <div className={"col m-0 pr-1 justify-content-end text-right"}>
                    <button aria-label={'close'} className={"btn btn-close-focus btn-tage-default-cancel"}
                            onClick={() => this.props.closeFocus()}>
                        X
                    </button>
                </div>
                <div className={"row m-0 pt-2 pb-2"}>
                    <div><h5 aria-label={'headline'}
                             className={"mt-0"}>{this.props.drugName + ", " + this.props.locationName}</h5></div>
                </div>
                <div className={"container2 m-0 pt-3 pl-0 kb-modal-scrollable"}>
                    <table className={"drug-event-table"}>
                        <thead>
                        {data[0]}
                        </thead>
                        <tbody>
                        {data.slice(1)}
                        </tbody>
                    </table>
                </div>
                <div className={"row m-0 pt-4 pr-3"}>
                    <div className={"col m-0 p-0 justify-content-end text-right"}>
                        <button aria-label={'print'}
                                onClick={() => this.createKbReport()}
                                className={"btn btn-tage-default-cancel"}>
                            Skriv ut
                        </button>
                    </div>
                </div>
            </div>
        )

        return (
            <div>
                <aside className="knarkbok-modal-background2"
                       data-testid="modal-background"
                       onClick={() => this.props.closeFocus()}/>
                <div className={"knarkbok-modal2 pr-3 justify-content-center pb-5"}>
                    {grid}
                </div>
            </div>
        )
    }

    public createKbReport() {
        const doc = new jsPDF('l');

        let drugName = this.props.drugName;
        const header = `Förbrukningsjournal för ${drugName}, ${this.props.locationName}`;
        doc.text(header, 14, 10);

        const atomicDrugEvents: string[][] = [];
        this.state.events.forEach((ev) => {
            atomicDrugEvents.push([ev.time,
                ev.userFullName,
                TranslationService.translation(ev.event.toString()),
                ev.additionalData ? TranslationService.translation(ev.additionalData) : "",
                ev.comment ? ev.comment + '' : '',
                ev.amount,
                ev.reportedTotal + '',
                ev.trueTotal + '',
                ev.balance,
                ev.warning ? "X" : ""])
        });

        autoTable(doc, {
            headStyles: {fillColor: [82, 206, 255]},
            head: [['Datum', 'Användare', 'Händelse', 'Specifikation', 'Kommentar', 'Antal', 'Räknat Saldo', 'Beräknat Saldo', 'Avvikelse', 'Epost skickat']],
            body: atomicDrugEvents,
        });

        if (this.state.partials) {
            this.state.partials.forEach((partial: PartialEvents) => {
                if (partial) {
                    doc.addPage('l')
                    const bottleLabel: string = 'Öppnad flaska, ' + partial.bookLabel;
                    const partialDrugEvents: string[][] = [];

                    partial.details.forEach((ev) => {
                        const eventType: KnarkbokType = ev.event;
                        const eventTypeName: string = TranslationService.translation(eventType.toString());

                        let weightBefore: number | undefined = ev.weightBefore;
                        let weightBeforeStr: string = '';

                        if (eventType !== KnarkbokType.created &&
                            weightBefore !== undefined &&
                            weightBefore !== 0) {
                            weightBeforeStr = '' + weightBefore;
                        }

                        partialDrugEvents.push([ev.time,
                            ev.userFullName,
                            eventTypeName,
                            ev.additionalData ? TranslationService.translation(ev.additionalData) : "",
                            ev.comment ? ev.comment + '' : '',
                            ev.amount,
                            weightBeforeStr,
                            ev.trueTotal + '',
                            ev.balance,
                            ev.warning ? "X" : ""])
                    });

                    doc.text(bottleLabel, 14, 10);

                    autoTable(doc, {
                        headStyles: {fillColor: [82, 206, 255]},
                        head: [['Datum', 'Användare', 'Händelse', 'Specifikation', 'Kommentar', 'Mängd', 'Vikt före', 'Vikt efter', 'Avvikelse (g)', 'Epost skickat']],
                        body: partialDrugEvents,
                    });
                }
            });
        }

        function addHeader(doc: any) {
            const pageCount = doc.internal.getNumberOfPages()
            doc.setFontSize(8)
            const today: Date = new Date();
            const dateStr: string = today.toISOString();
            const dateHeader = 'Utskriftsdatum: ' + dateStr.substr(0, 10);
            for (var i = 1; i <= pageCount; i++) {
                doc.setPage(i)
                doc.text(dateHeader, 282, 10, {
                    align: 'right'
                });
            }
        }

        function addFooter(doc: any) {
            const pageCount = doc.internal.getNumberOfPages()
            doc.setFontSize(8)
            for (var i = 1; i <= pageCount; i++) {
                doc.setPage(i)
                const footer = header + ', sida ' + String(i) + ' av ' + String(pageCount);
                doc.text(footer, 280, 200, {
                    align: 'right'
                })
            }
        }

        addHeader(doc);
        addFooter(doc);

        const date: string = new Date().toISOString();
        doc.save(`${drugName}-${this.props.locationName}-${date}.pdf`);
    }

    private static popup(e: React.MouseEvent<HTMLDivElement>, comment: string | undefined) {
        //TODO actual popup
        e.preventDefault()
        alert(comment);
    }
}

export default DrugEventFocus;
