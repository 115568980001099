import React from 'react';
import {Binder, BinderId, Drug, DrugId, DrugWithInventory, Partials} from "./models/Models";
import "./FullScreenModal.css"
import {BackendContext} from "../../infra/BackendContext";
import {Organisation} from "../model/Organisation";
import {EventBackendService} from "../register/v2/EventBackendService";
import TranslationService from "../../infra/TranslationService";
import UserSessionUtilService from "../model/UserSessionUtilService";
import {Resolution} from "../../infra/Constants";

interface props {
    binder: Binder | undefined
    flipModal: () => void;
    organisation: Organisation
    update: () => void;
    requireWitness: boolean;
}

interface state {
    drugs: { drug: Drug, partials?: Partials[] }[],
    valueMap: Map<DrugId, { amount: string, newCount: string }>;
    comment: string | undefined,
    showComment: boolean,
    searchArgument: string
    oldDrugs: DrugWithInventory[], //is this consistent (type) (whole check chain?)
    force: boolean
    showTopUpWarning: boolean,
    errorList: DrugId[],
    witnessWarning?: boolean
    witness?: string
}

export type TopUpResult = { result: boolean, errors: DrugId[] };

class FullScreenModal extends React.Component<props, state> {
    static contextType = BackendContext;

    constructor(props: any) {
        super(props);
        this.state = {
            drugs: [],
            valueMap: new Map(),
            comment: undefined,
            showComment: false,
            witness: "",
            searchArgument: "",
            oldDrugs: [],
            force: false,
            showTopUpWarning: false,
            errorList: []
        }
    }

    async componentDidMount() {
        const drugsPath: string = `/api/v1/knarkbok/books/binder/${this.props.binder?.id.id}`
        const drugUrl: string = EventBackendService.getUrl2(drugsPath);
        const drugsResponse: any = await this.context.get(drugUrl, {
            success: "",
            failure: ""
        }) as any;


        if (drugsResponse !== undefined && drugsResponse.data !== undefined) {
            let oldDrugs = drugsResponse.data.map((o: { drug: Drug, inventory: number, partials?: Partials[] }) => {
                return JSON.parse(JSON.stringify({...o.drug, inventory: o.inventory, partials: o.partials}))
            });


            let drugs = drugsResponse.data.map((o: { drug: Drug, partials?: Partials[] }) => {
                //putting all values to 0 is so that the values arent visible when you start a top up
                let partials = o.partials?.map((part) => {
                    return {...part, inventory: 0}
                })
                return {drug: o.drug, partials: partials}
            });


            this.setState({
                drugs: drugs,
                oldDrugs: JSON.parse(JSON.stringify(oldDrugs)) //TODO this parse stringify might now be unnecessary
            });
        }
    }

    render(): React.JSX.Element {
        const {props, tr} = this;

        const commentFlip = <div
            className={"ml-2"}
            title="Lägg till kommentar">
            <button className={"btn btn-tiny-expand"}
                    onClick={(e) => this.flipComment(e)}>
                {this.state.showComment ? "-" : "+"}
            </button>
        </div>;

        const commentField = <div>
            <div className={"row m-0 pb-1 pt-2"}>
                <div className={"col m-0 p-0"}>
                    <div className={"row m-0"}>
                        Kommentar:
                        {commentFlip}
                    </div>
                </div>
            </div>
            {
                this.state.showComment ? <React.Fragment>
                    <div className={"row ml-0 pl-0 pr-3 mb-2"}>
                        <textarea value={this.state.comment}
                                  rows={1}
                                  className={"max-size-input"}
                                  onChange={(e) => this.handleChangeComment(e)}
                        />
                    </div>
                </React.Fragment> : <div/>
            }
        </div>
        let medicineListClass = this.state.showComment ? "medicine-list pt-2 max-60" : "medicine-list pt-2";

        const searchBar = <div className={"col-4 pl-0"}>
            <div className={"row m-0"}><input className={"modal-input"}
                                              aria-label={"search-field"}
                                              placeholder={tr("Filter")}
                                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleSearch(e)}
                                              type={"text"} value={this.state.searchArgument}/></div>
        </div>

        let witness = searchBar
        if (this.props.requireWitness) {
            let cName = "col-5 "
            if (!!this.state.witnessWarning) {
                cName = cName + "witness-warning"
            }
            let witnessLabel = tr("Witness");
            witness = <div className={"row m-0 p-0"}>
                <div className={cName}>
                    <div className={"row"}><h5 className={"mt-2"}>{witnessLabel + ":"}</h5></div>
                    <div className={"row"}><input value={this.state.witness}
                                                  className={"modal-input"}
                                                  aria-label={"witness-field"}
                                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateWitness(e)}
                                                  type={"text"}/>
                    </div>
                </div>
            </div>
        }


        const forceText = this.state.showTopUpWarning ? <div className={"pr-5 pt-2"}>{tr("Är du säker?")}</div> : null
        const okButton = this.state.showTopUpWarning ?
            <button aria-label={"submit-button-force"} onClick={() => this.sendDeliveryForce()}
                    className={"btn btn-tage-default mr-3"}>{tr("Jag är säker")}</button> :
            <button aria-label={'submit-button'}
                    onClick={() => this.sendDelivery()}
                    className={"btn btn-tage-default mr-3"}>
                OK
            </button>;

        const type = UserSessionUtilService.getResolution() === Resolution.Desktop ? "text" : "number"

        const topUp: string = this.tr('top up amount');
        const weightWithCork = this.tr('Weight with cork');
        return (<div>
                <aside className="knarkbok-modal-background-fullscreen" onClick={props.flipModal}/>
                <div className={"knarkbok-modal-fullscreen"}>
                    <div className={"container p-0 m-0 justify-content-center"}>
                        <div aria-label={'headline'}
                             className={"row fullscreen-header-color"}>
                            <div className={"col-5"}>
                                <h5 className={"mt-0"}>
                                    Läkemedel
                                </h5>
                            </div>
                            <div className={"col-4"}>
                                <h5 className={"mt-0"}>
                                    {topUp}
                                </h5>
                            </div>
                            <div className={"col-3 pl-1"}>
                                <h5 className={"mt-0"}>
                                    Nytt saldo
                                </h5>
                            </div>
                        </div>

                        <div className={medicineListClass}>
                            {searchBar}
                            {this.state.drugs.map((drugObj, i) => {
                                if (this.isSearchedFor(drugObj.drug.name)) {

                                    let containsFault = this.state.errorList.find((id: DrugId) => drugObj.drug.id?.id === id.id);
                                    const classWithPossibleWarning = containsFault ? "row m-0 pb-1 pt-1 possibly-faulty" : "row m-0 pb-1 pt-1 ";


                                    let partials = <div></div>
                                    if (drugObj.partials && drugObj.partials.length > 0) {
                                        partials = <div className={"container m-0 p-0"}>{
                                            drugObj.partials.map((part) => {
                                                return <div key={part.name} className={"row ml-3 pt-2"}>
                                                    <div className={"col-6 pl-0"}>
                                                        &#8226; {part.name}
                                                    </div>
                                                    <div className={"col-3 text-right"}>
                                                        {weightWithCork}
                                                    </div>
                                                    <div className={"col3"}>
                                                        <input
                                                            onChange={(e) => this.updatePartialInventory(e, part.name, drugObj.drug.id)}
                                                            type={type} size={2}/>
                                                    </div>
                                                </div>
                                            })
                                        }</div>
                                    }


                                    return <div key={drugObj.drug.name + i} className={classWithPossibleWarning}>
                                        <div className={"col-5 pl-0"}>{drugObj.drug.name}</div>
                                        <div className={"col-4"}>
                                            <input value={this.getAmount(drugObj.drug.id)}
                                                   aria-label={drugObj.drug.name + "_amount"}
                                                   onChange={(e) => this.updateAmount(e, drugObj.drug.id)}
                                                   type={type}
                                                   size={2}/>
                                        </div>
                                        <div className={"col-3"}>
                                            <input value={this.getCount(drugObj.drug.id)}
                                                   aria-label={drugObj.drug.name + "_count"}
                                                   onChange={(e) => this.updateCount(e, drugObj.drug.id)}
                                                   type={type}
                                                   size={2}/>
                                        </div>
                                        {partials}
                                    </div>
                                } else {
                                    return null
                                }
                            })}

                        </div>
                        {witness}
                        {commentField}
                        <div className={"row from-bottom-rel justify-content-end"}>
                            {forceText}
                            {okButton}
                            <button aria-label={'cancel'}
                                    onClick={props.flipModal}
                                    className={"btn btn-tage-default-cancel mr-3"}>
                                Avbryt
                            </button>
                        </div>
                    </div>

                </div>
            </div>

        );
    }

    private getAmount(id: DrugId | undefined): string {
        if (id) {
            let amount = this.state.valueMap.get(id);
            if (amount) {
                return "" + amount.amount;
            }
        }
        return "";
    }

    private async updateAmount(e: React.ChangeEvent<HTMLInputElement>, id: DrugId | undefined) {
        const stripped = e.currentTarget.value.replace(/\D/g, '');
        this.removeError(id)
        if (id) {
            let valueMap = this.state.valueMap;

            let exists = valueMap.get(id);
            if (exists) {
                exists.amount = stripped;
            } else {
                valueMap.set(id, {amount: stripped, newCount: ""})
            }

            this.setState({valueMap: valueMap})
        }
    }

    private getCount(id: DrugId | undefined): string {
        if (id) {
            let count = this.state.valueMap.get(id);
            if (count) {
                return "" + count.newCount;
            }
        }
        return "";
    }

    private async updateCount(e: React.ChangeEvent<HTMLInputElement>, id: DrugId | undefined) {
        const stripped = e.currentTarget.value.replace(/\D/g, '');
        this.removeError(id)
        if (id) {
            let valueMap: Map<DrugId, {
                amount: string;
                newCount: string
            }> = this.state.valueMap;

            let exists = valueMap.get(id);
            if (exists) {
                exists.newCount = stripped;
            } else {
                valueMap.set(id, {amount: "", newCount: stripped})
            }

            this.setState({valueMap: valueMap})
        }
    }

    private flipComment(e: React.MouseEvent<HTMLButtonElement>): void {
        e.preventDefault();
        this.setState({showComment: !this.state.showComment})
    }

    private handleChangeComment(e: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({comment: e.currentTarget.value});
    }

    private async sendDelivery() {
        const {valueMap} = this.state;
        const binder = this.props.binder;

        //TODO check vs to old drugs..... <----

        const topUp: TopUpResult = FullScreenModal.topUpCorrect(valueMap, this.state.oldDrugs, this.state.drugs);
        if (!topUp.result) {
            this.setState({errorList: topUp.errors, showTopUpWarning: true})
        }

        if (topUp.result || this.state.force) {
            let payload: {
                binderId: BinderId | undefined,
                organisationId: string,
                comment: string | undefined,
                witness: string | undefined
                drugs: {
                    drugId: DrugId,
                    amount: string,
                    newBalance: string
                    partials?: { name: string, newBalance: string }[]
                }[]
            } = {
                binderId: binder?.id,
                organisationId: this.props.organisation.organisationId,
                comment: this.state.comment,
                witness: this.state.witness,
                drugs: []
            }

            for (const entry of valueMap) {
                let drugId = entry[0];
                const wantedPartials = this.state.drugs.find((drugObj) => drugObj.drug.id?.id === drugId.id)?.partials
                let partials: { name: string, newBalance: string }[] = [];

                if (wantedPartials) {
                    wantedPartials.forEach((part: Partials) => {
                        partials.push({name: part.name, newBalance: "" + part.inventory})
                    })
                }

                if (entry[1].amount && entry[1].newCount) {
                    payload.drugs.push({
                        drugId: entry[0],
                        amount: entry[1].amount,
                        newBalance: entry[1].newCount,
                        partials: partials
                    })
                }
            }

            console.log("payloads:", payload)

            if ((!this.props.requireWitness || (this.props.requireWitness && this.state.witness)) && !this.partiallyFilled(valueMap)) {
                const drugsPath: string = `/api/v1/knarkbok/top-up-drugs/`
                const drugUrl: string = EventBackendService.getUrl2(drugsPath);

                let topUpResponse = await this.context.post(drugUrl, JSON.stringify(payload), {
                    success: "",
                    failure: ""
                });

                if (topUpResponse.success) {
                    this.props.update();
                }

                this.props.flipModal();
            }
        }

        if (this.props.requireWitness && !this.state.witness) {
            this.setState({witnessWarning: true})
        }
    }

    private updateWitness(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({witness: e.currentTarget.value}, () => this.clearWitness())
    }

    private tr(s: string): string {
        return TranslationService.translation(s);
    }

    private partiallyFilled(valueMap: Map<DrugId, { amount: string; newCount: string }>) {
        for (const entry of valueMap) {
            if ((!!entry[1].amount && !entry[1].newCount) || (!entry[1].amount && !!entry[1].newCount)) {
                return true;
            }
        }

        return false;
    }

    private handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({searchArgument: e.currentTarget.value})
    }

    private isSearchedFor(name: string | undefined) {
        const {searchArgument} = this.state;
        if (!searchArgument) {
            return true;
        }

        if (!name) {
            return false;
        }

        const loweredSearchArg = searchArgument.toLowerCase();
        const loweredName = name.toLowerCase();

        return loweredName.includes(loweredSearchArg);
    }

    static topUpCorrect(valueMap: Map<DrugId, {
        amount: string;
        newCount: string
    }>, startingState: DrugWithInventory[], drugs?: { drug: Drug, partials?: Partials[] }[]): TopUpResult {
        let result = true;
        let errors: DrugId[] = []

        for (const drug of startingState) {
            if (drug.id) {

                let updatedValues = null; //no, for some godforsaken reason we cant use valueMap.find... maybe js drops references and they are !== and that point?
                for (const [key, value] of valueMap) {
                    if (key.id === drug.id.id) {
                        updatedValues = value;
                    }
                }

                if (updatedValues) {
                    if (!(drug.inventory + +updatedValues?.amount === +updatedValues?.newCount)) {
                        result = false;
                        errors.push(drug.id)
                    }
                }

                if (drug.partials && drug.partials.length > 0) {
                    for (let index = 0; index < drug.partials.length; index++) { //this is a c-like for loop because... the linter doesn't like foreach reference outside of loop?
                        const part: Partials = drug.partials[index];
                        const expectedInv = part.inventory
                        const wantedDrugId = drug.id;
                        const wantedDrug = drugs?.find((d) => d.drug.id?.id === wantedDrugId?.id)
                        const wantedPartial = wantedDrug?.partials?.find((pa) => pa.name === part.name)

                        if (wantedPartial) {
                            if (!(+wantedPartial.inventory === +expectedInv)) { //TODO add leniency? 3g?
                                result = false;
                                if (drug.id) {
                                    errors.push(drug.id) //TODO refine to partial
                                }
                            }
                        }
                    }
                }
            }
        }

        return {result, errors};
    }

    private sendDeliveryForce() {
        this.setState({force: true}, () => this.sendDelivery())
    }

    private removeError(drugId: DrugId | undefined) {
        const index = this.state.errorList.findIndex((id) => id.id === drugId?.id);
        if (index >= 0) {
            this.state.errorList.splice(index, 1);
            this.setState({errorList: this.state.errorList, showTopUpWarning: false})
        }
    }

    private clearWitness() {
        if (this.state.witness) {
            this.setState({witnessWarning: false,  showTopUpWarning: false})
        }
    }

    private updatePartialInventory(e: React.ChangeEvent<HTMLInputElement>, partialName: string, drugId: DrugId | undefined) {
        if (drugId) {
            this.removeError(drugId)

            const updatedValue = e.currentTarget.value;
            const wantedDrug = this.state.drugs.find((drug) => drug.drug.id?.id === drugId.id);

            let wantedPartial = wantedDrug?.partials?.find((part) => part.name === partialName)
            if (wantedPartial) {
                wantedPartial.inventory = updatedValue;
            }

            this.setState({drugs: this.state.drugs})
        }
    }
}

export default FullScreenModal;
