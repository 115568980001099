import React, {Component} from "react";
import {TageResponse} from "../../../pages/administration/user/UserAdministration";
import {Role} from "../roles/v3/Role";
import {User} from "./SearchUser";
import {BackendContext} from "../../../infra/BackendContext";
import {Organisation} from "../roles/v3/Organisation";
import {EventBackendService} from "../../register/v2/EventBackendService";
import "./UserOverview.css"

interface props {
    user: User
    organisation: Organisation,
    currentOrganisationId: string | undefined,
    hideModal: () => void
    updateOrganisationRoleList: () => Promise<void>
    currentRoles: Role[]
}

interface RoleResponse extends TageResponse {
    data: { roles: Role[] }
}

interface state {
    currentRoles: Role[]
    possibleRoles: Role[],
}

class EditOrganisationRolesModal extends Component<props, state> {
    static contextType = BackendContext;

    constructor(props: any) {
        super(props);
        this.state = {currentRoles: this.props.currentRoles, possibleRoles: []};
    }

    render() {
        const {user, organisation} = this.props;
        const currentRoles = this.state.currentRoles;
        const headline = "Manage roles for " + user.firstName + " " + user.firstName + " in " + organisation.name
        let roleClass = "";
        let possibleRoles = this.state.possibleRoles;
        return (
            <div>
                <aside className="edit-organisation-roles-background" onClick={() => this.hideModal()}/>
                <div className="edit-organisation-roles">
                    <div className="modal-container">
                        <h3>{headline}</h3>
                        <div className="row">
                            <div className="col-12">
                                <h4>{"Current roles:"}</h4>
                                <div className={"border-role " + roleClass}>
                                    {this.getCurrentRoles(currentRoles)}
                                </div>
                            </div>
                        </div>

                        <div className="row pt-3">
                            <div className="col-12">
                                <h4>{"Available roles:"}</h4>
                                <div className="border-role">
                                    {this.getAvailableRoles(possibleRoles)}
                                </div>
                            </div>
                        </div>

                        <div className="row pt-3">
                            <div className="ml-3 mr-4">
                                <button className="btn btn-tage-default" aria-label="confirm button"
                                        onClick={() => this.submit()}>
                                    {"Save"}
                                </button>
                            </div>
                            <div className="">
                                <button className="btn btn-tage-default-cancel" aria-label="cancel button"
                                        onClick={() => this.hideModal()}>
                                    {"Cancel"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }

    private getAvailableRoles(possibleRoles: Role[]) {
        if (possibleRoles.length > 0) {
            return <>
                {
                    possibleRoles?.map((role: Role) => (
                        <div key={role.id} title={role.description}
                             className={"row mt-1 p-0 " + this.deactivateIfCurrentRole(role)}>
                            <div className="col-10">{role.name}</div>
                            <div className="col-2 text-right ">
                                <button
                                    aria-label={role.id + "_add"}
                                    className="btn btn-tage-small-round btn-g"
                                    disabled={this.currentRole(role)}
                                    onClick={() => this.addRole(role)
                                    }
                                >
                                    {"+"}
                                </button>
                            </div>
                        </div>
                    ))
                }
            </>;
        }
        return <div/>

    }

    private getCurrentRoles(currentRoles: Role[]) {
        if (currentRoles.length > 0) {
            return <>
                {
                    currentRoles.map((role: Role) => (
                        <div key={role.id} title={role.description} className="row mt-1 p-0">
                            <div className="col-10 text-left">{role.name}</div>
                            <div className="col-2 text-right">
                                <button
                                    aria-label={role.id + "_remove"}
                                    className="btn btn-tage-small-round btn-r"
                                    onClick={() => this.removeRole(role.id)}
                                >
                                    {"-"}
                                </button>
                            </div>
                        </div>
                    ))
                }
            </>;
        }
        return <div/>
    }

    async componentDidMount() {
        let currentOrganisationId: string | undefined = this.props.currentOrganisationId;
        if (currentOrganisationId !== undefined) {
            const organisationId = this.props.organisation.id;
            const path: string = `/api/v3/backoffice/organisation/roles/${organisationId}`;
            const url: string = EventBackendService.getUrl2(path);
            const possibleRolesResponse: any = await this.context.getWithOrg(url, currentOrganisationId, {
                success: "",
                failure: ""
            }) as RoleResponse;
            if (possibleRolesResponse && possibleRolesResponse.success) {
                if (possibleRolesResponse.data) {
                    this.setState({possibleRoles: possibleRolesResponse.data})
                }
            }
        }
    }

    private async submit() {
        let currentOrganisationId = this.props.currentOrganisationId;
        if (currentOrganisationId !== undefined) {
            const {currentRoles} = this.state;
            const path: string = `/api/v4/backoffice/user/organisation/roles`
            const url: string = EventBackendService.getUrl2(path);
            let response = await this.context.putWithOrg(url, currentOrganisationId,
                JSON.stringify({
                    organisationId: this.props.organisation.id,
                    roles: currentRoles.map((role) => role.id),
                    userName: this.props.user.userName
                }),
                {
                    success: "Update roles inside organisation",
                    failure: "Failed to update roles inside organisation"
                }) as TageResponse;

            if (response && response.success) {
                await this.props.updateOrganisationRoleList()
                this.hideModal();
            }
        }
    }

    private removeRole(roleId: string) {
        let currentRoles: Role[] = this.state.currentRoles;
        const wanted = currentRoles.find((role) => role.id === roleId)
        if (wanted) {
            currentRoles.splice(currentRoles.indexOf(wanted), 1);
            this.setState({currentRoles: currentRoles});
        }
    }

    private addRole(role: Role) {
        const currentRoles: Role[] = this.state.currentRoles;
        if (!currentRoles.includes(role)) {
            currentRoles.push(role)
        }
        this.setState({currentRoles: currentRoles})
    }

    private deactivateIfCurrentRole(input: Role) {
        let currentRole: boolean = this.currentRole(input);
        if (currentRole) {
            return " deselected"
        }
        return "";
    }

    private currentRole(input: Role) {
        let selected = this.state.currentRoles.find((r: Role) => r.id === input.id);
        return selected !== undefined && selected !== null;
    }

    private hideModal() {
        this.props.hideModal();
    }
}

export default EditOrganisationRolesModal;