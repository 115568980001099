import React from "react";
import "./KnarkbokModal.css"
import "./ToggleSwitch.css"
import "./Knarkbokuserpage.css"
import {KnarkbokService} from "./Knarkbokservice";
import {Organisation} from "../model/Organisation";
import Knarkbokuserpage from "./Knarkbokuserpage";
import {DrugOrganisation} from "./models/Models";

interface props {
    KBService: KnarkbokService,
    drugBook: string
    testing?: boolean
}

interface state {
    organisation: Organisation | undefined,
    drugDealer: boolean,
    drugJournalOverview: boolean,
    spinner: boolean
}

class DrugBook extends React.Component<props, state> {
    constructor(props: any) {
        super(props);

        this.state = {
            organisation: undefined,
            drugDealer: false,
            drugJournalOverview: false,
            spinner: true
        }
    }

    async componentDidMount() {
        const drugBook = this.props.drugBook;
        this.props.KBService.getOrganisation(drugBook).then(
            (drugOrganisation: DrugOrganisation) => {
                if (drugOrganisation) {
                    const organisation: Organisation | undefined = drugOrganisation.organisation;
                    const drugJournalOverview: boolean = drugOrganisation.drugJournalOverview;
                    const drugDealer: boolean = drugOrganisation.drugDealer;
                    this.setState(
                        {
                            organisation: organisation,
                            drugJournalOverview: drugJournalOverview,
                            drugDealer: drugDealer,
                            spinner: false
                        });
                } else {
                    this.setState(
                        {
                            spinner: false
                        });
                }
            }
        );
    }

    render(): React.JSX.Element {
        if (this.state.spinner) {
            return <div aria-label={"drug-book-spinner"}
                        className={"spinner"}/>
        }

        const organisation: Organisation | undefined = this.state.organisation;
        const drugBook: string = this.props.drugBook;
        const drugJournalOverview: boolean = this.state.drugJournalOverview;
        const drugDealer: boolean = this.state.drugDealer;
        const kbService: KnarkbokService = this.props.KBService;
        const allowedUser: boolean = drugDealer || drugJournalOverview;

        if (organisation !== undefined && allowedUser) {
            return <Knarkbokuserpage
                organisation={organisation}
                drugBook={drugBook}
                drugLord={drugJournalOverview}
                drugUser={drugDealer}
                KBService={kbService}
                testing={this.props.testing}
            />
        } else {
            window.location.hash = '#/';
            return <div aria-label={'not allowed to see drug book'}/>;
        }
    }
}

export default DrugBook;
