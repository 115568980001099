import React from 'react';
import CreateUserModal from "./CreateUserModal";
import {User} from './SearchUser';

interface props {
    organisationId: string | undefined;
    userCreated: (user: User | undefined) => void;
}

interface state {
    showModal: boolean;

}

class CreateUser extends React.Component<props, state> {
    constructor(props: any) {
        super(props);

        this.state = {showModal: false};
        this.userCreated = this.userCreated.bind(this);
    }

    render() {
        let createUserModal = <div></div>
        if (this.state.showModal) {
            createUserModal = <CreateUserModal
                hideModal={() => this.hideModal()}
                userCreated={this.userCreated}>
            </CreateUserModal>
        }
        return (
            <div>
                {createUserModal}
                <div className={"row pt-4 user-overview-header"}>
                    <div>
                        <button
                            aria-label={"createUser"}
                            className={"btn user-overview-button"}
                            onClick={() => this.showModal()}>Create new user
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    private showModal = () => {
        this.setState({showModal: true});
    };

    private hideModal = () => {
        this.setState({showModal: false});
    };

    private userCreated = (user: User) => {
        this.props.userCreated(user);
    }
}

export default CreateUser;
