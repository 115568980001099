import React from 'react';
import {User} from './SearchUser';
import UserOrganisationRolesView from "./UserOrganisationRolesView";
import "./UserOverview.css"

interface props {
    organisationId: string | undefined;
    user: User | undefined;
}

interface state {
    user: User | undefined;
}


class UserOverview extends React.Component<props, state> {
    constructor(props: any) {
        super(props);
        this.state = {user: this.props.user};
    }

    componentDidUpdate(prevProps: Readonly<props>, prevState: Readonly<state>, snapshot?: any) {
        if (prevProps.user !== this.props.user) {
            this.setState({user: this.props.user})
        }
    }

    render() {
        const user = this.state.user;
        if (user === undefined) {
            return <div/>
        }
        return (
            <div>
                <h4 className={"row pt-4 pl-3"}>
                    {user.firstName} {user.lastName}
                </h4>
                <div className={"user-border"}>
                    <div className={"row pt-2 card-h pl-4"}>
                        User details
                    </div>
                    <div className={"row pt-1 pl-4"}>
                        {user.email}
                    </div>
                    <div className={"row pt-1 pl-4"}>
                        {user.phone}
                    </div>
                    <div className={"row pt-1 pl-4"}>
                        Last login date: {user.lastLogin}
                    </div>
                </div>

                <UserOrganisationRolesView user={user} organisationId={this.props.organisationId}/>
            </div>
        );
    }

}

export default UserOverview;