import React from 'react';
import {ModalDisplayBinder} from "../KnarkbokModal";
import {KnarkbokService} from "../Knarkbokservice";
import {BookId, DiscardReason, KnarkbokType, Partials} from "../models/Models";
import "./Move.css";
import TranslationService from "../../../infra/TranslationService";
import {EventBackendService} from "../../register/v2/EventBackendService";
import {BackendContext, BackendInterface} from "../../../infra/BackendContext";
import UserSessionUtilService from "../../model/UserSessionUtilService";
import {Resolution} from "../../../infra/Constants";

interface props {
    closeModal: (bookId?: BookId, sentEmailWarning?: boolean) => void;
    book: ModalDisplayBinder;
    KBService: KnarkbokService;
    isDruglord: boolean;
    partials?: Partials[]
}

interface state {
    spinner: boolean;
    inputDiscarded: string | undefined
    newBalance: string | undefined,
    displayCheckWarning: boolean,
    discardReason: DiscardReason | undefined,
    comment: string | undefined;
    showComment: boolean;
    partialsToDiscard: string[]
    discardType?: DiscardType
}

enum DiscardType {
    PARTIAL = "partial",
    ATOMIC = "atomic"
}

class Discard extends React.Component<props, state> {
    static contextType: React.Context<BackendInterface> = BackendContext;

    constructor(props: any) {
        super(props);

        let dType = undefined;
        if (!this.props.partials || this.props.partials.length < 1) {
            dType = DiscardType.ATOMIC;
        }

        this.state = {
            spinner: false,
            inputDiscarded: undefined,
            newBalance: undefined,
            displayCheckWarning: false,
            discardReason: undefined,
            showComment: false,
            comment: undefined,
            partialsToDiscard: [],
            discardType: dType,
        }
    }

    render() {
        if (this.state.spinner) {
            return this.getSpinner()
        }

        if (!this.state.discardType) {
            return this.chooseDiscardType()
        }

        if (this.state.discardType === "atomic") {
            return this.discardAtomic();
        } else {
            return this.discardPartial();
        }
    }

    private getSpinner() {
        return <div aria-label={"administer-drug-spinner"}
                    className={"spinner"}/>;
    }

    private submit = async (forceSubmit?: boolean) => {
        if (this.state.discardType === "partial") {
            const modalD: ModalDisplayBinder = this.props.book;
            const path: string = `/api/v1/knarkbok/partialevents/discard`
            const url = EventBackendService.getUrl2(path);

            const partials: { bottleLabel: string; }[] = []

            this.state.partialsToDiscard.forEach((name) => {
                partials.push({bottleLabel: name})
            })

            const payload = {
                drugId: modalD.drug.id,
                binderId: modalD.binderId,
                discardReason: this.state.discardReason,
                partials: partials,
                comment: this.state.comment
            }

            this.setState({
                spinner: true,
            });

            await this.context.post(url, JSON.stringify(payload), {
                success: "",
                failure: ""
            })

            this.setState({
                spinner: false,
            });

            if (forceSubmit) {
                this.props.closeModal(modalD.bookId, true);
            } else {
                this.props.closeModal()
            }

        } else {
            if (this.props.book?.bookId && this.state.inputDiscarded) {
                const newBalance: string | undefined = this.state.newBalance;
                const discardReason: DiscardReason | undefined = this.state.discardReason;
                const type = KnarkbokType.discard;
                const oldInventory = this.props.book.inventory;
                const check = this.props.KBService.aboutToSubmitCheck(oldInventory, newBalance, type, +this.state.inputDiscarded);

                if ((check || forceSubmit) && discardReason !== undefined) {
                    const book = this.props.book;
                    const amount: number = +this.state.inputDiscarded;
                    const comment = this.state.comment;
                    const path: string = `/api/v1/knarkbok/events/`
                    const url = EventBackendService.getUrl2(path);

                    let payload = {
                        eventType: KnarkbokType[type],
                        bookId: book.bookId,
                        amount: amount,
                        newBalance: newBalance,
                        discardReason: discardReason,
                        comment: comment
                    };

                    let success: string = '';

                    this.setState({
                        spinner: true,
                    });

                    await this.context.post(url, JSON.stringify(payload), {
                        success: success,
                        failure: ""
                    })

                    this.setState({
                        spinner: false,
                    });

                    if (forceSubmit) {
                        this.props.closeModal(book.bookId, true);
                    } else {
                        this.props.closeModal()
                    }
                } else
                    this.setState({displayCheckWarning: true});
            }
        }
    }

    handleDiscard(e: React.ChangeEvent<HTMLInputElement>) {
        this.closeWarning();
        const stripped = e.currentTarget.value.replace(/\D/g, '');
        this.setState({inputDiscarded: stripped})
    }

    handleNewBalance(e: React.ChangeEvent<HTMLInputElement>) {
        this.closeWarning();
        const stripped = e.currentTarget.value.replace(/\D/g, '');
        this.setState({newBalance: stripped})
    }

    handleReason(e: React.ChangeEvent<HTMLSelectElement>) {
        const discardReason = e.currentTarget.value as DiscardReason;
        this.setState({discardReason})
    }

    private flipComment(e: React.MouseEvent<HTMLButtonElement>): void {
        e.preventDefault();
        this.setState({showComment: !this.state.showComment})
    }

    private handleChangeComment(e: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({comment: e.currentTarget.value});
    }

    private closeWarning() {
        this.setState({displayCheckWarning: false})
    }

    private closeModal() {
        this.props.closeModal();
    }

    private chooseDiscardType() {
        const buttonClass: string = 'btn btn-tage-drug-book-move';

        return <div className={'row'}>
            <div className={"col text-center"}>
                <button aria-label={'partial'}
                        className={buttonClass}
                        onClick={() => this.selectDiscardType(DiscardType.PARTIAL)}>
                    Öppnad flaska
                </button>
            </div>
            <div className={"col text-center"}>
                <button aria-label={'atomic'}
                        className={buttonClass}
                        onClick={() => this.selectDiscardType(DiscardType.ATOMIC)}>
                    Hel flaska
                </button>
            </div>
        </div>
    }

    private selectDiscardType(type: DiscardType) {
        this.setState({discardType: type})
    }

    private discardAtomic() {

        const type = UserSessionUtilService.getResolution() === Resolution.Desktop ? "text" : "number"

        const commentFlip = <div
            aria-label={'show hide comment'}
            className={"pt-2"}
            title="Lägg till kommentar">
            <button className={"btn btn-tiny-expand"} onClick={(e) => this.flipComment(e)}>
                {this.state.showComment ? "-" : "+"}
            </button>
        </div>;

        const commentField = <div>
            <div className={"row m-0 pt-2"}>
                <div className={"pt-2 pr-2"}>Kommentar:</div>
                {commentFlip}
            </div>
            {
                this.state.showComment ? <React.Fragment>
                    <div className={"row m-0"}>
                        <textarea value={this.state.comment}
                                  onChange={(e) => this.handleChangeComment(e)}
                                  className={"max-size-input"}
                        />
                    </div>
                </React.Fragment> : <div/>
            }
        </div>

        //TODO this part is repeated in all innermodals, and as such should be outsourced
        const warningStuff = this.state.displayCheckWarning ? <div>
            <div className={"row m-0 p-0 pb-3 justify-content-center"}>
                <h5 className={"innermodal-warning-text"}>Är du säker på att antalet är korrekt?</h5>
            </div>
            <div className={"row m-0 pr-4 justify-content-end"}>
                <button className={"btn btn-red"} onClick={() => this.submit(true)}>Jag är säker</button>
                <button className={"btn btn-tage-default-cancel  ml-2 mr-2"}
                        onClick={() => this.closeWarning()}>Avbryt
                </button>
            </div>
        </div> : "";

        const submitCancelButtons = this.state.displayCheckWarning ? "" :
            <div className={"row m-0 pr-4 pt-2 justify-content-end"}>

                <button aria-label={'ok'}
                        disabled={!this.state.discardReason || !this.state.inputDiscarded || !this.state.newBalance}
                        className={"btn btn-tage-default"} onClick={() => this.submit()}>
                    OK
                </button>
                <button aria-label={'cancel'}
                        className={"btn btn-tage-default-cancel ml-2 mr-2"}
                        onClick={() => this.closeModal()}>
                    Avbryt
                </button>
            </div>;


        return (
            <div className={"container m-0 p-0"}>
                <div className={"row"}>
                    <div className={"col"}></div>
                    <div className={"col-6 m-0 p-0"}>
                        <div className={"row m-0 pb-1"}>
                            Antal kasserade:
                        </div>
                        <div className={"row m-0 pb-0"}>
                            <input aria-label={'amount'}
                                   value={this.state.inputDiscarded}
                                   className={"max-size-input m-0"}
                                   onChange={(e) => this.handleDiscard(e)}
                                   type={type}/>
                        </div>
                    </div>
                    <div className={"col"}></div>
                </div>
                <div className={"row pt-2"}>
                    <div className={"col"}></div>
                    <div className={"col-6 m-0 p-0"}>
                        <div className={"row m-0 pb-1"}>
                            Anledning:
                        </div>
                        <div className={"row m-0 pb-0"}>
                            <select aria-label={'discard reason'}
                                    defaultValue={"default_selected"} className={"max-size-input"}
                                    onChange={(e) => this.handleReason(e)}>
                                <option disabled value={"default_selected"}>-- Välj --</option>
                                <option
                                    value={DiscardReason.expired}>{TranslationService.translation(DiscardReason.expired)}</option>
                                <option
                                    value={DiscardReason.broken}>{TranslationService.translation(DiscardReason.broken)}</option>
                                <option
                                    value={DiscardReason.notadministered}>{TranslationService.translation(DiscardReason.notadministered)}</option>
                            </select>
                        </div>
                    </div>
                    <div className={"col"}></div>
                </div>
                <div className={"row pt-2"}>
                    <div className={"col"}></div>
                    <div className={"col-6 m-0 p-0"}>
                        <div className={"row m-0 pb-1"}>
                            Nytt saldo:
                        </div>
                        <div className={"row m-0 pb-0"}>
                            <input aria-label={'new balance'}
                                   value={this.state.newBalance}
                                   className={"max-size-input m-0"}
                                   onChange={(e) => this.handleNewBalance(e)}
                                   type={type}/>
                        </div>
                    </div>
                    <div className={"col"}></div>
                </div>
                <div className={"row"}>
                    <div className={"col"}></div>
                    <div className={"col-6 m-0 p-0"}>
                        {commentField}
                    </div>
                    <div className={"col"}></div>
                </div>
                {warningStuff}
                {submitCancelButtons}
            </div>
        );
    }

    private discardPartial() {
        const {tr} = this;

        //TODO fix comment field duplication
        const commentFlip = <div
            aria-label={'show hide comment'}
            className={"pt-2"}
            title="Lägg till kommentar">
            <button className={"btn btn-tiny-expand"} onClick={(e) => this.flipComment(e)}>
                {this.state.showComment ? "-" : "+"}
            </button>
        </div>;

        const commentField = <div>
            <div className={"row m-0 pt-2"}>
                <div className={"pt-2 pr-2"}>Kommentar:</div>
                {commentFlip}
            </div>
            {
                this.state.showComment ? <React.Fragment>
                    <div className={"row m-0"}>
                        <textarea value={this.state.comment}
                                  onChange={(e) => this.handleChangeComment(e)}
                                  className={"max-size-input"}
                        />
                    </div>
                </React.Fragment> : <div/>
            }
        </div>

        const warningStuff = this.state.displayCheckWarning ? <div>
            <div className={"row m-0 p-0 pb-3 justify-content-center"}>
                <h5 className={"innermodal-warning-text"}>Är du säker på att antalet är korrekt?</h5>
            </div>
            <div className={"row m-0 pr-4 justify-content-end"}>
                <button className={"btn btn-red"} onClick={() => this.submit(true)}>Jag är säker</button>
                <button className={"btn btn-tage-default-cancel  ml-2 mr-2"}
                        onClick={() => this.closeWarning()}>Avbryt
                </button>
            </div>
        </div> : "";

        let okButtonDisabled = false;
        if (this.state.discardType === "partial") {
            if (!this.partialsInOrder()) {
                okButtonDisabled = true;
            }
        } else {
            okButtonDisabled = (!this.state.discardReason || !this.state.inputDiscarded || !this.state.newBalance)
        }

        const submitCancelButtons = this.state.displayCheckWarning ? "" :
            <div className={"row m-0 pr-4 pt-2 justify-content-end"}>

                <button aria-label={'ok'}
                        disabled={okButtonDisabled}
                        className={"btn btn-tage-default"} onClick={() => this.submit()}>
                    OK
                </button>
                <button aria-label={'cancel'}
                        className={"btn btn-tage-default-cancel ml-2 mr-2"}
                        onClick={() => this.closeModal()}>
                    Avbryt
                </button>
            </div>;


        let partials = <div className={"row pl-5"}>
            <div className={"col pl-0"}>
                {tr("Inga öppnade flaskor")}
            </div>
        </div>

        if (this.props.partials && this.props.partials.length > 0) {
            partials = <div className={"row pl-5"}>
                <div className={"col m-0 p-0"}>
                    <div className={"row m-0 pb-1"}>
                        {tr("Anledning:")}
                    </div>
                    <div className={"row m-0 p-0"}>
                        {this.getDiscardReasonSelect()}
                    </div>
                    <div className={"row m-0 pt-2"}>
                        <div className={"col m-0 p-0"}>
                            {this.props.partials.map((part) => this.createPartialRow(part))}
                        </div>
                    </div>
                </div>
            </div>
        }

        return <div className={"container m-0 p-0"}>
            {partials}
            <div className={"row pl-5"}>
                {commentField}
            </div>
            {warningStuff}
            {submitCancelButtons}
        </div>
    }

    private getDiscardReasonSelect() {
        return <div className={"row m-0 pb-0"}>
            <select aria-label={'discard reason'}
                    defaultValue={"default_selected"} className={"max-size-input"}
                    onChange={(e) => this.handleReason(e)}>
                <option disabled value={"default_selected"}>-- Välj --</option>
                <option
                    value={DiscardReason.expired}>{TranslationService.translation(DiscardReason.expired)}</option>
                <option
                    value={DiscardReason.broken}>{TranslationService.translation(DiscardReason.broken)}</option>
                <option
                    value={DiscardReason.lost_bottle}>{TranslationService.translation(DiscardReason.lost_bottle)}</option>
            </select>
        </div>;
    }

    tr(s: string) {
        return TranslationService.translation(s)
    }

    private createPartialRow(part: Partials) {
        const toBeDiscarded = this.state.partialsToDiscard.find((string) => string === part.name)
        return <div className={"row m-0 p-0"} key={part.name}>
            <div className={""}><input checked={!!toBeDiscarded} type={"checkbox"}
                                       onChange={() => this.handleDiscardPartial(part.name)}/></div>
            {part.name}
        </div>
    }

    private partialsInOrder() {
        return this.state.discardType === "partial" && this.state.partialsToDiscard.length > 0 && this.state.discardReason;
    }

    private handleDiscardPartial(name: string) {
        const i = this.state.partialsToDiscard.indexOf(name)
        if (i > -1) {
            this.state.partialsToDiscard.splice(i, 1);
        } else {
            this.state.partialsToDiscard.push(name)
        }

        this.setState({partialsToDiscard: this.state.partialsToDiscard});
    }
}

export default Discard;
