import React from 'react';
import CreateUser from "./CreateUser";
import SearchUser, {User} from "./SearchUser";
import UserOverview from "./UserOverview";

interface props {
    organisationId: string | undefined;
}

interface state {
    selectedUser: User | undefined;
}

class ManageUsers extends React.Component<props, state> {
    constructor(props: any) {
        super(props);

        this.state = {selectedUser: undefined};

        this.selectUser = this.selectUser.bind(this);
    }


    render() {
        return (
            <div>
                <div className="row m-0 pl-2 pr-2 justify-content-center actionHeader pt-1">
                    <h4>Manage users</h4>
                </div>
                <div className={"container"}>
                    <div><CreateUser organisationId={this.props.organisationId} userCreated={this.selectUser}/></div>
                    <div><SearchUser organisationId={this.props.organisationId} selectUser={this.selectUser}/></div>
                    <div><UserOverview organisationId={this.props.organisationId} user={this.state.selectedUser}/></div>
                </div>
            </div>
        );
    }

    private selectUser(selectUser: User | undefined) {
        this.setState({selectedUser: selectUser});
    }

}

export default ManageUsers;
