import React from 'react';

const def: BackendInterface = {
    post: async (url: string, payload: any, message?: Message): Promise<Result> => {
        return {success: true}
    },
    unsecurePost: async (url: string, payload: any, message?: Message): Promise<Result> => {
        return {success: true}
    },
    put: async (url: string, payload: any, message?: Message): Promise<Result> => {
        return {success: true}
    },
    delete: async (url: string, message?: Message): Promise<Result> => {
        return {success: true}
    },
    get: async (url: string, message?: Message): Promise<Result> => {
        return {success: true}
    },
    getWithOrg: async (url: string, organisationId: string, message?: Message): Promise<Result> => {
        return {success: true};
    },
    unsecureGet: async (url: string, message?: Message): Promise<Result> => {
        return {success: true}
    },
    test: async (expectedResult: boolean,
                 message?: Message): Promise<Result> => {
        return {success: expectedResult, message: "this is a test", httpErrorCode: expectedResult ? 200 : 418}
    },
}
export const BackendContext: React.Context<BackendInterface> = React.createContext(def);

export interface Result {
    success: boolean,
    message?: string,
    httpErrorCode?: number,
    data?: any
}

export interface Message {
    success?: string,
    failure?: string
}

export interface BackendInterface {
    post: (url: string, payload: any, message?: Message) => Promise<Result>
    unsecurePost?: (url: string, payload: any, message?: Message) => Promise<Result>
    put: (url: string, payload: any, message?: Message) => Promise<Result>
    delete: (url: string, message?: Message) => Promise<Result>
    get: (url: string, message?: Message) => Promise<Result>
    getWithOrg?: (url: string, organisationId: string, message?: Message) => Promise<Result>
    putWithOrg?: (url: string, organisationId: string, payload: any, message?: Message) => Promise<Result>
    unsecureGet?: (url: string, message?: Message) => Promise<Result>
    test?: (expectedResult: boolean, message?: Message) => Promise<Result>
}