import React from 'react';
import {BalanceType, BinderId, BookId, Drug, DrugEventSummary, DrugEventSummaryStation, DrugId} from "./models/Models";
import "./KnarkbokOverview.css"
import DrugEventFocus from "./DrugEventFocus";
import {KnarkbokService} from "./Knarkbokservice";
import {BackendContext} from "../../infra/BackendContext";
import {Organisation} from "../model/Organisation";
import SearchBar from "./SearchBar";

interface props {
    KBService: KnarkbokService,
    organisation: Organisation
}

interface state {
    grid: DrugEventSummary[]
    selectedDrugId: DrugId | undefined
    selectedBinderId: BinderId | undefined
    selectedBook: BookId | undefined
    showFocusWindow: boolean;
    headers: string[];
    searchArgument: string
}

class KnarkbokOverview extends React.Component<props, state> {
    static contextType = BackendContext;

    constructor(props: any) {
        super(props);

        this.state = {
            grid: [],
            selectedDrugId: undefined,
            selectedBinderId: undefined,
            selectedBook: undefined,
            showFocusWindow: false,
            headers: [],
            searchArgument: ""
        }
    }


    async componentDidMount() {
        await this.fetchGrid();
    }

    private async fetchGrid() {
        const orgId: string = this.props.organisation.organisationId;
        const grid = await this.props.KBService.getDrugEventSummaryStationForOrg(orgId) as any;

        if (grid && grid.overview && grid.headers) {
            this.setState({grid: grid.overview, headers: grid.headers});
        }
    }

    async componentDidUpdate(prevProps: Readonly<props>, _prevState: Readonly<state>, _snapshot?: any) {
        if (prevProps.organisation !== this.props.organisation) {
            await this.fetchGrid();
        }
    }

    render() {
        const topics = this.getHeadersForGrid()

        let selectedDrugName: string = '';
        let selectedLocationName: string = '';
        if (this.state.selectedBook) {
            for (let i: number = 0; i < this.state.grid.length; i++) {
                for (let j = 0; j < this.state.grid[i].cells.length; j++) {
                    if (this.state.grid[i].cells[j].bookId === this.state.selectedBook) {
                        selectedDrugName = this.state.grid[i].drug.name;
                        selectedLocationName = this.state.grid[i].cells[j].binderName
                    }
                }
            }
        }

        let focusWindow: React.JSX.Element | string;
        if (this.state.showFocusWindow) {
            focusWindow = <DrugEventFocus locationName={selectedLocationName}
                                          drugName={selectedDrugName}
                                          closeFocus={this.closeFocusWindow}
                                          drugId={this.state.selectedDrugId}
                                          binderId={this.state.selectedBinderId}
                                          book={this.state.selectedBook}
                                          KBService={this.props.KBService}/>;
        } else {
            focusWindow = "";
        }

        let headers = (
            <thead>
            <tr>
                {topics.map((col, i) =>
                    <th key={col + " " + i} className={i === 0 ? "sticky-column" : ""}>
                        {col}
                    </th>
                )}
            </tr>
            </thead>
        );

        let grid = this.state.grid.map((row: DrugEventSummary, index: number) => {
            const containsDeficiency = row.cells.find((cel) => cel.balanceType === BalanceType.deficiency);
            const containsExcess = row.cells.find((cel) => cel.balanceType === BalanceType.excess);
            let backgroundColoring = "";
            if (containsDeficiency && containsExcess) {
                backgroundColoring = "containsDeficiencyAndExcess"
            } else if (containsDeficiency) {
                backgroundColoring = "containsDeficiency"
            } else if (containsExcess) {
                backgroundColoring = "containsExcess"
            }

            if (this.isSearchedFor(row.drug.name)) {
                return <tr key={row.drug.name}
                           className={(index % 2 === 0 ? "kb-grayed2" : "")}>
                    <td className={backgroundColoring + " sticky-column"}>{row.drug.name}</td>
                    {
                        row.cells.map((col, index) => {
                            const drug: Drug = row.drug;
                            return <td key={row.drug.name + " " + index}
                                       aria-label={row.drug.name + " " + index}
                                       onClick={col.binderId.id !== -1 ? () => this.handleCellClick(drug, col) : undefined}
                                       className={KnarkbokOverview.getColumnName(col)}>
                                {((col.binderId === undefined || col.binderId.id !== -1) ? col.amount : "")}
                            </td>
                        })
                    }
                </tr>
            }else{
                return null;
            }
        })

        return <div>
            <div className="row m-0 pl-2 pr-2 justify-content-center actionHeader pt-1">
                <h4>Översikt</h4>
            </div>
            <div className={"container"}>
                <div className={"container-filter pt-3"}>
                    <SearchBar resetSearch={() => this.resetSearch()}
                               searchArgument={this.state.searchArgument}
                               handleSearch={(e) => this.handleSearch(e)}/>
                </div>
                <div aria-label={'drug-journal-overview'}
                     className={"b-wrapper"}>
                    <div className={"pt-3 overflow-b"}>
                        <table className={"kb-overview-table"}>
                            {headers}

                            <tbody>
                            {grid}
                            </tbody>
                        </table>
                        <div id={"focus-window-wrapper"}>
                            {focusWindow}
                        </div>

                    </div>
                </div>

            </div>

        </div>
    }

    private getHeadersForGrid(): string[] {
        const names: string[] = ["Läkemedel"];

        this.state.headers.forEach((header: string) => {
            names.push(header)
        })
        return names;
    }


    private static getColumnName(col: DrugEventSummaryStation) {
        let defaultClassName = "text-right ";
        if (col.balanceType === BalanceType.excess) {
            return defaultClassName + "containsExcess"
        }
        if (col.balanceType === BalanceType.deficiency) {
            return defaultClassName + "containsDeficiency"
        }
        return defaultClassName;
    }

    private async handleCellClick(drug: Drug, col: DrugEventSummaryStation) {
        const wrapper = document.getElementById("focus-window-wrapper");
        wrapper?.classList.add("drop-down-animation")
        const drugId: DrugId | undefined = drug.id;
        const binderId: BinderId = col.binderId
        const selectedBook: BookId = col.bookId;

        this.setState(
            {
                selectedDrugId: drugId,
                selectedBinderId: binderId,
                selectedBook: selectedBook,
                showFocusWindow: true
            }
        );
    }

    closeFocusWindow = () => {
        const wrapper = document.getElementById("focus-window-wrapper");
        wrapper?.classList.remove("drop-down-animation")
        this.setState({showFocusWindow: false})
    }

    private handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({searchArgument: e.currentTarget.value})
    }

    private isSearchedFor(name: string | undefined) {
        const {searchArgument} = this.state;
        if (!searchArgument) {
            return true;
        }

        if (!name) {
            return false;
        }

        const loweredSearchArg = searchArgument.toLowerCase();
        const loweredName = name.toLowerCase();

        return loweredName.includes(loweredSearchArg);
    }

    private resetSearch() {
        this.setState({searchArgument: ""});
    }
}

export default KnarkbokOverview;
