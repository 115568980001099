import React, {ChangeEvent} from 'react';
import {BackendContext, BackendInterface} from "../../../infra/BackendContext";
import {TageResponse} from "../../../pages/administration/user/UserAdministration";
import {EventBackendService} from "../../register/v2/EventBackendService";

interface props {
    organisationId: string | undefined;
    selectUser: (user: User | undefined) => void;
}

interface state {
    searchValue: string,
    users: User[],
    selectedUser: User | undefined
}

export interface User {
    userName: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    lastLogin: string
}

interface SearchUserResponse extends TageResponse {
    data: { users: User[] }
}


class SearchUser extends React.Component<props, state> {
    static contextType: React.Context<BackendInterface> = BackendContext;

    constructor(props: any) {
        super(props);

        this.state = {
            selectedUser: undefined,
            users: [],
            searchValue: ""
        };
    }

    render() {
        return (
            <div>
                <div className={"row pt-4 user-overview-header"}>
                    <h3>Search for a user</h3>
                </div>
                <input className={"row m-0 pb-1 mb-4 search-input"}
                       type={"text"}
                       id={"user"}
                       aria-label={"search-user"}
                       value={this.state.searchValue}
                       onChange={(e: ChangeEvent<HTMLInputElement>) => this.onChange(e)}
                />
                {this.getUsers(this.state.users)}
            </div>

        );
    }

    private onChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (this.state.selectedUser) {
            this.setState({selectedUser: undefined});
            this.selectUser(undefined);
        }
        const searchValue = e.currentTarget.value;
        this.setState({searchValue: searchValue});


        if (searchValue.length > 2) {
            this.searchFor(searchValue);
        } else {
            this.setState({users: []});
        }
    }

    private selectUser(user: User | undefined) {
        this.props.selectUser(user);
        this.setState({selectedUser: user, users: []})
    }

    private async searchFor(searchValue: string) {
        const path = `/api/v4/backoffice/user/search/${searchValue}`;
        const url: string = EventBackendService.getUrl2(path);

        const response: any = await this.context.getWithOrg(url, this.props.organisationId, {
            success: "",
            failure: ""
        }) as SearchUserResponse;

        if (response && response.success) {
            this.setState({users: response.data})
        } else {
            this.setState({users: []})
        }
    }

    private getUsers(users: User[]) {
        if (users && users.length > 0) {
            return users.map((user: User, _index: number) => {
                let className = "row m-0 pb-1 no-line-break";
                const name = user.firstName + " " + user.lastName + " (" + user.email + ")";

                return <div className={className} key={user.email}>
                    <div aria-label={name}
                         onClick={() => this.selectUser(user)}>
                        {name}
                    </div>
                </div>
            });
        }

    }
}

export default SearchUser;
