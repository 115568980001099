import {Organisation} from "../../model/Organisation";

export interface Drug {
    id?: DrugId;
    name: string,
    canEdit: boolean
    allowPartials?: boolean
}

export interface DrugWithInventory extends Drug {
    inventory: number,
    partials?: Partials[]
}

export interface DrugId {
    id: number
}

export interface BookId {
    id: number
}

export interface DrugEvent {
    drug: Drug
    timestamp: string,
    time: string,
    userFullName: string,
    event: KnarkbokType,
    amount: string,
    reportedTotal: number,
    trueTotal: number,
    balance: string,
    warning: boolean,
    additionalData?: string,
    comment?: string,
    weightBefore?: number
}

export interface DrugEventSummary {
    drug: Drug,
    cells: DrugEventSummaryStation[]
}

export interface DrugEventSummaryStation {
    binderId: BinderId,
    binderName: string,
    amount: number | undefined,
    balanceType: BalanceType,
    bookId: BookId
}

export enum BalanceType {
    balanced = "balanced",
    excess = "excess",
    deficiency = "deficiency"
}

export enum DiscardReason {
    expired = "expired",
    broken = "broken",
    notadministered = "notadministered",
    lost_bottle = "lost_bottle"
}

export interface BinderId {
    id: number
}

export interface Binder {
    id: BinderId,
    locationName: string,
    accessOnlyQr?: boolean,
    acceptDelivery?: boolean,
    canBeArchived?: boolean,
    canBeEdited: boolean,
}

export interface Partials {
    name: string, //todo - change to bottleLabel (?)
    inventory: string
}

export const ALLOWED_DEVIATION = 3;

export interface Book {
    id: BookId,
    binderId: BinderId,
    drug?: Drug,
    drugId?: DrugId
    inventory: number,
    recentlyChecked: boolean,
    hidden?: boolean
    partials?: Partials[]
    sentEmailWarning?: boolean
    allowedToCreatePartials?: boolean
}

export enum KnarkbokType {
    administer,
    discard,
    check,
    move_neg,
    move_pos,
    correction_neg,
    correction_pos,
    druglord_correction_pos,
    druglord_correction_neg,
    druglord_check,
    move,
    drug_name_change,
    binder_name_change,
    top_up,
    partial_administer,
    created
}

export interface DrugOrganisation {
    organisation: Organisation | undefined,
    drugJournalOverview: boolean,
    drugDealer: boolean
}

export interface ArchivedDrugBooks {
    archivedDrugBooks: ArchivedDrugBook[]
}

export interface ArchivedDrugBook {
    binderId: BinderId,
    name: string,
    activeFrom: string,
    activeTo: string
}
